import { Dispatch } from "react";
import { QualifierActionType } from "../../context/qualifiers/types/QualifierProviderType";
import qualifierBindingApi from "../qualifierBinding.api";

export const fetchProductsById = async (
	id: number,
	dispatch: Dispatch<QualifierActionType>
) => {
	try {
		const payload = await qualifierBindingApi.getBindingEntriesByPoolId(id);
		if (payload.items.length) {
			dispatch({
				type: "STORE_PRODUCTS",
				payload,
			});
		}
	} catch (error: any) {
		console.error(error);
		let message = "Unknown Error";
		if (error instanceof Error) {
			message = error.message;
		}
		console.error(message)
	}
};
