import { useState, MouseEvent, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { Box, Typography, Menu, MenuItem } from "@mui/material";
import { SubserviceMenuProps } from "../types/MenuProps";
import parse from "html-react-parser";
import { NavDropdownItemsType } from "../types/NavItems";
import { useInvokeWizard } from "../../../../../context/wizard/hooks/useInvokeWizard";

export const DesktopMenu = ({ navItems }: SubserviceMenuProps) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
	const [dropDownItems, setDropDownItems] = useState<
		undefined | NavDropdownItemsType[]
	>(undefined);

	const handleOpenNavMenu = (event: MouseEvent<HTMLElement>) => {
		const dropDownMenuItem = navItems.find(
			(item) => item.class === event.currentTarget.className
		);
		setDropDownItems(dropDownMenuItem?.dropdownItems);
		setIsOpen(true);
		setAnchorElNav(event.currentTarget);
	};
	const handleCloseNavMenu = () => {
		setIsOpen(false);
		setAnchorElNav(null);
	};

	const { invokeWizard } = useInvokeWizard();

	useEffect(() => {}, [dropDownItems]);

	return (
		<>
			<Box
				sx={{
					display: { xs: "none", md: "flex" },
				}}
			>
				<Menu
					sx={{ mt: "45px" }}
					id="menu-appbar"
					anchorEl={anchorElNav}
					anchorOrigin={{
						vertical: "top",
						horizontal: "right",
					}}
					keepMounted
					transformOrigin={{
						vertical: "top",
						horizontal: "right",
					}}
					open={Boolean(anchorElNav)}
					onClose={handleCloseNavMenu}
				>
					{dropDownItems &&
						dropDownItems.map((item, index) => (
							<Typography key={index}>
								{item.linkTo && (
									<Link className="nav-item" to={item.linkTo}>
										<MenuItem>{item.name}</MenuItem>
									</Link>
								)}
								{item.useWizard && (
									<button
										className="nav-item"
										onClick={() =>
											invokeWizard(item.url ?? "/")
										}
										data-testid="faq"
									>
										<MenuItem>{item.name}</MenuItem>
									</button>
								)}
								{item.url && !item.useWizard && (
									<a
										className="nav-item"
										href={item.url}
										target="_blank"
										rel="noreferrer"
									>
										<MenuItem>{item.name}</MenuItem>
									</a>
								)}
							</Typography>
						))}
				</Menu>
				{navItems.map((page, index) => (
					<Typography
						className="nav-item"
						textAlign="center"
						key={index}
					>
						{page.linkTo && (
							<Link className="nav-item" to={page.linkTo}>
								<MenuItem>{page.name}</MenuItem>
							</Link>
						)}
						{page.url && !page.class && (
							<a
								className="nav-item"
								href={page.url}
								target="_blank"
								rel="noreferrer"
							>
								<MenuItem>{page.name}</MenuItem>
							</a>
						)}
						{page.useWizard && (
							<button
								className="nav-item"
								onClick={() => invokeWizard(page.url ?? "/")}
								data-testid="faq"
							>
								<MenuItem>{page.name}</MenuItem>
							</button>
						)}
						{page.isDropdown && (
							<button
								className={page.class ?? `ess-${page.name}`}
								onClick={handleOpenNavMenu}
							>
								<MenuItem>
									{page.name}{" "}
									{isOpen
										? parse("&#8593;")
										: parse("&#8595;")}
								</MenuItem>
							</button>
						)}
					</Typography>
				))}
			</Box>
		</>
	);
};
