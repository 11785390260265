import { FC, Suspense, lazy } from "react";
import { Box, CircularProgress, Container } from "@mui/material";
import { Banner } from "../../components/Banner";
import { ProductLines } from "./ProductLines";
import { Features } from "../../components/Features";
import { Testimonials } from "../../components/Testimonials";
const Pricing = lazy(() => import("./Pricing"));

export const Home: FC = () => {
	return (
		<Container>
			<Banner />
			<ProductLines />
			<Features />
			<Box
				sx={{
					margin: "1.6rem auto",
					maxWidth: "80%",
				}}
			>
				<Testimonials />
			</Box>
			<Suspense fallback={<CircularProgress />}>
				<Pricing />
			</Suspense>
		</Container>
	);
};
