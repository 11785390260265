import { useReducer, ReactNode, useState } from "react";
import { initialState } from "./InitialState";
import QualifierContext from "./QualifierContext";
import { QualifierActionType } from "./types/QualifierProviderType";
import { State } from "./types/StateType";

const QualifierContextReducer = (
	state: State,
	action: QualifierActionType
): State => {
	switch (action.type) {
		case "RESET_STATE": {
			return initialState;
		}

		case "RESET_PRODUCTS": {
			return { ...state, products: initialState.products };
		}

		case "POP_QUALIFIER": {
			const updatedState = { ...state };

			return {
				...updatedState,
				qualifiedUrl: updatedState.qualifiedUrl.slice(0, -1),
				qualifiers: updatedState.qualifiers.slice(0, -1),
			};
		}

		case "POP_QUALIFIED_URL": {
			const updatedState = { ...state };

			return {
				...updatedState,
				qualifiedUrl: updatedState.qualifiedUrl.slice(0, -1),
			};
		}

		case "PUSH_URL_FRAGMENT": {
			return {
				...state,
				qualifiedUrl: [
					...state.qualifiedUrl,
					action.payload.urlFragment,
				],
			};
		}

		case "SELECT_QUALIFIER_VALUE": {
			const updatedState = { ...state };
			const payload = action.payload;
			updatedState.isLoading = true;
			const currentLevelIndex = updatedState.qualifiers.findIndex(
				(q) => q.level === payload.level
			);
			updatedState.qualifiedUrl.splice(currentLevelIndex - 1, 100);
			updatedState.qualifiers.splice(currentLevelIndex + 1, 100);

			if (updatedState.qualifiers[currentLevelIndex])
				updatedState.qualifiers[currentLevelIndex].values =
					updatedState.qualifiers[currentLevelIndex].values.map(
						(val) => {
							if (val.id === payload.id) {
								updatedState.qualifiedUrl.push(val.urlFragment);
								return { ...val, selected: true };
							} else return { ...val, selected: false };
						}
					);

			return updatedState;
		}

		case "SET_SELECTED_QUALIFIER": {
			const updatedState = { ...state };
			const payload = action.payload;
			const currentLevelIndex = updatedState.qualifiers.findIndex(
				(q) => q.level === payload.level
			);

			updatedState.qualifiers[currentLevelIndex].values =
				updatedState.qualifiers[currentLevelIndex].values.map((val) => {
					if (val.id === payload.id) {
						const isInQualifiedUrl = updatedState.qualifiedUrl.find(
							(frag) => frag === val.urlFragment
						);
						if (!isInQualifiedUrl)
							updatedState.qualifiedUrl.push(val.urlFragment);
						return { ...val, selected: true };
					} else return { ...val, selected: false };
				});

			return updatedState;
		}

		case "ADD_NEXT_QUALIFIER_LEVEL": {
			const updatedState = { ...state };
			updatedState.isLoading = false;
			const payload = action.payload;
			const level = payload.values[0].level;

			const newSelection = {
				id: payload.id,
				level,
				name: payload.name,
				parent: payload.parent,
				values: payload.values.map((value) => {
					return { ...value, selected: false };
				}),
			};

			if (updatedState.qualifiers[level - 1]) {
				updatedState.qualifiers[level - 1] = newSelection;
			} else {
				updatedState.qualifiers.push(newSelection);
			}

			return updatedState;
		}

		case "STORE_PRODUCTS": {
			const updatedState = {
				...state,
				products: action.payload,
			};

			return updatedState;
		}

		case "STORE_WIZARD_URL": {
			const updatedState = {
				...state,
				wizardUrl: action.payload,
				isWizardRequested: true,
			};

			return updatedState;
		}

		case "SET_DIRECT_URL": {
			const updatedState = {
				...state,
				isDirectUrl: action.payload,
			};

			return updatedState;
		}

		case "SET_HUBSPOT_LOADED": {
			const updatedState = {
				...state,
				isLoadedHubspot: action.payload,
			};

			return updatedState;
		}

		case "SET_SERVICE": {
			const updatedState = {
				...state,
				service: action.payload,
			};

			return updatedState;
		}
	}
};

export const QualifierProvider = (props: {
	children: ReactNode;
}): JSX.Element => {
	const [state, dispatch] = useReducer(QualifierContextReducer, initialState);
	const [currentLevel, setCurrentLevel] = useState<number>(0);
	const [filterLevel, setFilterLevel] = useState<number>(0);

	return (
		<QualifierContext.Provider
			value={{
				state,
				dispatch,
				currentLevel,
				setCurrentLevel,
				filterLevel,
				setFilterLevel,
			}}
		>
			{props.children}
		</QualifierContext.Provider>
	);
};

export default QualifierProvider;
