import { createContext } from "react";
import { initialState } from "./InitialState";
import { WizardContextType } from "./types/WizardContextType";

export const WizardContext = createContext<WizardContextType>({
	state: initialState,
	dispatchWizard: () => undefined,
});

export default WizardContext;
