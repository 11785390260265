import { Container, Grid, Typography } from "@mui/material";
import { Footer } from "../../components/Footer";
import { Navbar } from "../../components/Navbar";
import { Products } from "../../components/Products";
import { Qualifiers } from "../../components/Qualifiers";
import { navItems } from "../navItems";

const fontFamily = "Roboto, Helvetica Neue, Helvetica, Arial, sans-serif";
const textColor = "#363636";
const primaryColor = "#a81d3f";
const secondaryColor = "#fff";
const accentColor = "#196d79";
const backgroundImage = `url(${process.env.REACT_APP_S3_IMAGE_BASE_URL}/cpwm/cpwm_hero.jpg)`;

const Home = () => {
	return (
		<>
			<Grid container columns={12} sx={{ fontFamily }}>
				<Grid item lg={12} md={12} sm={12} xs={12}>
					<Navbar
						navItems={navItems}
						backgroundColor={secondaryColor}
						color={textColor}
						service="cpwm"
						logoUrl="cpwm_logo.jpg"
					/>
				</Grid>
				<Grid
					item
					lg={10}
					md={12}
					sm={12}
					xs={12}
					sx={{
						backgroundImage,
						backgroundRepeat: "no-repeat",
						backgroundSize: "cover",
						backgroundPosition: "center 10%",
						mx: "auto",
						height: 250,
						top: 0,
						left: 0,
					}}
				/>
				<Grid
					item
					lg={12}
					md={12}
					sm={12}
					xs={12}
					sx={{
						minHeight: "50vh",
						mx: "auto",
					}}
				>
					<Grid container columns={12}>
						<Grid
							item
							lg={10}
							md={10}
							sm={12}
							xs={12}
							sx={{
								mx: "auto",
								my: "30px",
								backgroundColor: "rgba(255,255,255,0.9)",
								padding: "10px",
								borderRadius: "10px",
							}}
						>
							<Container sx={{ mb: 6, textAlign: "center" }}>
								<Typography variant="h5" mb={2}>
									Welcome to the World Market Alcohol and Food
									Server Training Portal, powered by
									MyFoodAndBevTraining!
								</Typography>
								<Typography>
									You have been directed here to complete
									external training required for your role.
									Please use the drop-down menu and select the
									product line for your required training,
									then select the state your store is in, then
									click the &#34;Enroll Now&#34; button for
									the course you&#39;ve been assigned. If you
									have any questions or concerns regarding the
									use of this site or which training you need
									to take, please email
									WM_Alcohol@WorldMarket.com.
								</Typography>
							</Container>
							<Qualifiers />
							<Products
								primaryColor={primaryColor}
								secondaryColor={secondaryColor}
								accentColor={accentColor}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item lg={12} md={12} sm={12} xs={12}>
					<Footer
						backgroundColor={secondaryColor}
						color={textColor}
					/>
				</Grid>
			</Grid>
		</>
	);
};

export default Home;
