import { MouseEvent, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Drawer,
	IconButton,
	List,
	MenuItem,
	Tooltip,
	Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { SubserviceMenuProps } from "../types/MenuProps";
import { NavDropdownItemsType } from "../types/NavItems";
import { useInvokeWizard } from "../../../../../context/wizard/hooks/useInvokeWizard";

export const MobileMenu = ({
	navItems,
	hamburgerColor,
}: SubserviceMenuProps) => {
	const { invokeWizard } = useInvokeWizard();
	const [dropDownItems, setDropDownItems] = useState<
		undefined | NavDropdownItemsType[]
	>(undefined);

	const [openDrawer, setOpenDrawer] = useState<boolean>(false);
	const [isExpanded, setIsExpanded] = useState<boolean>(false);

	const toggleAccordian = (event: MouseEvent<HTMLDivElement>) => {
		const dropDownMenuItem = navItems.find(
			(item) => item.name === event.currentTarget.innerText
		);
		setDropDownItems(dropDownMenuItem?.dropdownItems);
		setIsExpanded(!isExpanded);
	};
	const toggleDrawer = () => setOpenDrawer(!openDrawer);
	const closeDrawer = () => setOpenDrawer(false);

	useEffect(() => {}, [dropDownItems]);

	return (
		<>
			<Drawer open={openDrawer} onClose={closeDrawer} anchor="right">
				<List data-testid="mobileMenu">
					{navItems &&
						navItems.map((page, index) => (
							<div key={index}>
								{page.linkTo && (
									<Link
										data-testid="menuItem"
										to={page.linkTo}
										onClick={closeDrawer}
									>
										<MenuItem data-testid="listItem">
											{page.name}
										</MenuItem>
									</Link>
								)}
								{page.url && !page.class && (
									<a
										href={page.url}
										onClick={closeDrawer}
										target="_blank"
										rel="noreferrer"
									>
										<MenuItem>{page.name}</MenuItem>
									</a>
								)}
								{page.useWizard && (
									<button
										className="ess-help"
										onClick={() =>
											invokeWizard(
												`${process.env.REACT_APP_TXN_URL}/helps/catalog/mst`
											)
										}
										data-testid="faq"
										style={{}}
									>
										<MenuItem>{page.name}</MenuItem>
									</button>
								)}
								{page.isDropdown && (
									<Tooltip title="Select product line">
										<Accordion
											expanded={isExpanded}
											onClick={toggleAccordian}
										>
											<AccordionSummary
												expandIcon={<ExpandMoreIcon />}
												aria-controls="panel-content"
												id="panel-header"
											>
												<MenuItem>{page.name}</MenuItem>
											</AccordionSummary>
											<AccordionDetails>
												{dropDownItems &&
													dropDownItems.map(
														(item, index) => (
															<Typography
																key={index}
															>
																{item.linkTo && (
																	<Link
																		to={
																			item.linkTo
																		}
																	>
																		<MenuItem>
																			{
																				item.name
																			}
																		</MenuItem>
																	</Link>
																)}
																{item.useWizard && (
																	<button
																		style={{
																			border: "none",
																			background:
																				"none",
																			padding: 0,
																			margin: 0,
																			width: "100%",
																		}}
																		onClick={() =>
																			invokeWizard(
																				item.url ??
																					"/"
																			)
																		}
																		data-testid="faq"
																	>
																		<MenuItem>
																			{
																				item.name
																			}
																		</MenuItem>
																	</button>
																)}
																{item.url &&
																	!item.useWizard && (
																		<a
																			href={
																				item.url
																			}
																			target="_blank"
																			rel="noreferrer"
																		>
																			<MenuItem>
																				{
																					item.name
																				}
																			</MenuItem>
																		</a>
																	)}
															</Typography>
														)
													)}
											</AccordionDetails>
										</Accordion>
									</Tooltip>
								)}
							</div>
						))}
				</List>
			</Drawer>
			<IconButton
				onClick={toggleDrawer}
				data-testid="hamburger"
				sx={{ color: hamburgerColor }}
			>
				<MenuIcon />
			</IconButton>
		</>
	);
};
