import { MouseEvent, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Drawer,
	IconButton,
	List,
	ListItem,
	Tooltip,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { SubserviceMenuProps } from "../types/MenuProps";
import { NavDropdownItemsType } from "../types/NavItems";
import { useInvokeWizard } from "../../../../../context/wizard/hooks/useInvokeWizard";

export const MobileMenu = ({
	navItems,
	qualifier,
	getQualifiersByParentId,
	hamburgerColor,
}: SubserviceMenuProps) => {
	const [openDrawer, setOpenDrawer] = useState<boolean>(false);
	const [expandedDropdown, setExpandedDropdown] = useState<string | null>(
		null
	);
	const [dropDownItems, setDropDownItems] = useState<
		undefined | NavDropdownItemsType[]
	>(undefined);

	const toggleAccordian = (
		event: MouseEvent<HTMLDivElement>,
		itemName: string
	) => {
		const dropDownMenuItem = navItems.find(
			(item) => item.name === event.currentTarget.innerText
		);
		setDropDownItems(dropDownMenuItem?.dropdownItems);
		setExpandedDropdown((prev) => (prev === itemName ? null : itemName));
	};
	const toggleDrawer = () => setOpenDrawer(!openDrawer);
	const closeDrawer = () => setOpenDrawer(false);

	const setProductLineAndCloseNavMenu = (id: number, level: number) => {
		getQualifiersByParentId(id, level);
		closeDrawer();
	};

	const { invokeWizard } = useInvokeWizard();

	useEffect(() => {}, [dropDownItems]);

	const subservicePath = window.location.pathname;
	return (
		<>
			<Drawer open={openDrawer} onClose={closeDrawer} anchor="right">
				<List data-testid="mobileMenu">
					{navItems &&
						navItems.map((page, index) => (
							<div key={index}>
								{page.linkTo && (
									<Link
										data-testid="menuItem"
										to={page.linkTo}
										onClick={closeDrawer}
									>
										<ListItem data-testid="listItem">
											{page.name}
										</ListItem>
									</Link>
								)}
								{page.url && !page.class && (
									<a href={page.url} onClick={closeDrawer}>
										<ListItem>{page.name}</ListItem>
									</a>
								)}
								{page.class && !page.isDropdown && (
									<button
										className="ess-help"
										onClick={() =>
											invokeWizard(
												`${process.env.REACT_APP_TXN_URL}/helps/catalog/mst`
											)
										}
										data-testid="faq"
										style={{ fontSize: 16 }}
									>
										<ListItem>{page.name}</ListItem>
									</button>
								)}
								{page.isDropdown && (
									<Tooltip title="Select product line">
										<Accordion
											expanded={
												expandedDropdown === page.name
											}
											onClick={(event) =>
												toggleAccordian(
													event,
													page.name
												)
											}
										>
											<AccordionSummary
												expandIcon={<ExpandMoreIcon />}
												aria-controls="panel-content"
												id="panel-header"
											>
												<ListItem>{page.name}</ListItem>
											</AccordionSummary>
											<AccordionDetails>
												{qualifier &&
													!page.dropdownItems &&
													qualifier.values.map(
														(q, index) => (
															<Link
																to={`${subservicePath}/${q.urlFragment}`}
																key={index}
																onClick={() =>
																	setProductLineAndCloseNavMenu(
																		q.id,
																		qualifier.level
																	)
																}
															>
																<ListItem>
																	{q.value}
																</ListItem>
															</Link>
														)
													)}
												{dropDownItems &&
													dropDownItems.map(
														(item, index) => (
															<ListItem
																key={index}
															>
																{item.linkTo && (
																	<Link
																		className="nav-dropdown-item"
																		to={
																			item.linkTo
																		}
																	>
																		{
																			item.name
																		}
																	</Link>
																)}
																{item.useWizard && (
																	<button
																		className="nav-dropdown-item"
																		onClick={() =>
																			invokeWizard(
																				item.url ??
																					"/"
																			)
																		}
																		data-testid="faq"
																	>
																		{
																			item.name
																		}
																	</button>
																)}
																{item.url &&
																	!item.useWizard && (
																		<a
																			className="nav-dropdown-item"
																			href={
																				item.url
																			}
																			target="_blank"
																			rel="noreferrer"
																		>
																			{
																				item.name
																			}
																		</a>
																	)}
															</ListItem>
														)
													)}
											</AccordionDetails>
										</Accordion>
									</Tooltip>
								)}
							</div>
						))}
				</List>
			</Drawer>
			<IconButton
				onClick={toggleDrawer}
				data-testid="hamburger"
				sx={{ color: hamburgerColor }}
			>
				<MenuIcon />
			</IconButton>
		</>
	);
};
