import services from "../../service.map";
import { Service } from "../qualifiers/types/StateType";

export default function findService(service: number | string) {
	if (typeof service === "number") {
		const serviceConfig = Object.values(services).find(
			(val: Service) => val.id === service
		);
		return serviceConfig ? serviceConfig : services.mfbt;
	}
	if (typeof service === "string") {
		if (service === "" || service === "products") return services.mfbt;
		return services[service];
	}
}
