import { createContext } from "react";
import { initialState } from "./InitialState";
import { QualifierContextType } from "./types/QualifierContextType";

export const QualifierContext = createContext<QualifierContextType>({
	state: initialState,
	dispatch: () => undefined,
	currentLevel: 0,
	setCurrentLevel: () => undefined,
	filterLevel: 0,
	setFilterLevel: () => undefined,
});

export default QualifierContext;
