import { FC, MouseEvent, useEffect } from "react";
import useQualifierContext from "../../../context/qualifiers/hooks/useQualifierContext";
import { QualifierProvider } from "../../../context/qualifiers/QualifierProvider";
import { useNavigate } from "react-router-dom";
import { Grid, Container, Box, Typography } from "@mui/material";
import { primaryColor } from "../../../app/theme";
import { Qualifier } from "../../../context/qualifiers/types/StateType";
import { ProductLineCards } from "../../../components/ProductLineCards";
import { useFetchLevelAndProducts } from "../../../context/qualifiers/hooks/useFetchLevelAndProducts";

export const ProductLines: FC = () => {
	const navigate = useNavigate();
	const {
		state: { qualifiers },
		dispatch,
	} = useQualifierContext();
	const { fetchNextLevelAndProducts } = useFetchLevelAndProducts();
	// GET LEVEL 2 QUALIFIERS (PRODUCT LINES)
	const productLines: Qualifier = qualifiers.filter(
		(q: Qualifier) => q.level === 2
	)[0];

	useEffect(() => {
		// RESET TO INITIAL STATE
		dispatch({ type: "RESET_STATE" });
	}, [dispatch]);

	const selectProductLine = async (event: MouseEvent) => {
		const { currentTarget } = event;
		const { id, level, url } = (currentTarget as HTMLDivElement).dataset;

		fetchNextLevelAndProducts(Number(id), Number(level), false);

		navigate(`/products/${url}`);
	};

	const colorRed = { color: primaryColor };

	return (
		<>
			<QualifierProvider>
				<Container
					sx={{ padding: 0, marginBottom: "3rem", minHeight: 550 }}
				>
					<Box sx={{ margin: "1.6rem auto", maxWidth: "80%" }}>
						<Typography variant="h5" textAlign="center">
							ONLINE <span style={colorRed}>ALCOHOL</span>{" "}
							&amp;&nbsp;
							<span style={colorRed}>FOOD SAFETY</span> COURSES
						</Typography>
						<Typography
							variant="body1"
							textAlign="center"
							className="leadhead"
						>
							Our comprehensive Alcohol and Food Safety courses
							are certified and licensed in each state we service.
							Our courses are offered exclusively online, with
							live and email help to ensure you complete your
							training quickly and easily.
						</Typography>
					</Box>
					<Grid
						container
						columns={15}
						direction="row"
						rowSpacing={2}
						columnSpacing={1}
						sx={{ justifyContent: "center" }}
					>
						{productLines && (
							<ProductLineCards
								productLines={productLines}
								selectProductLine={selectProductLine}
								mediumValue={6}
								largeValue={4}
							/>
						)}
					</Grid>
				</Container>
			</QualifierProvider>
		</>
	);
};
