import { NavItemsType } from "../components/Navbar/types/NavItems";

export const navItems: NavItemsType = [
	{
		name: "Home",
		url: "/bjs",
	},
	{
		name: "Log In",
		url: `${process.env.REACT_APP_PORTAL_URL}/mst/login`,
	},
	{
		name: "Products",
		isDropdown: true,
	},
	{
		name: "FAQ/Support",
		url: `${process.env.REACT_APP_TXN_URL}/helps/catalog/mst`,
		class: "ess-help",
	},
];
