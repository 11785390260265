import qualifierLevelApi from "../qualifierLevel.api";

export const getLevelPoolId = async (
	id: number,
	getDecorators: boolean,
	tableName?: string,
	rowId?: number
) => {
	try {
		const payload = await qualifierLevelApi.getRawQualifiersByParentId(
			id,
			getDecorators,
			tableName,
			rowId
		);
		return Number(payload.id);
	} catch (error: any) {
		return Number(id);
	}
};
