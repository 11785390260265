import { Box, Paper } from "@mui/material";
import { FC } from "react";
import { testimonialData } from "../../../api/decorator.api";
import { primaryColor } from "../../../app/theme";

export const Testimonial: FC<{
	handleModalOpen: (testimonialData: testimonialData) => void;
	testimonialData: testimonialData;
}> = (props) => {
	return (
		<>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					userSelect: "none",
				}}
			>
				<Paper
					elevation={3}
					sx={{
						display: "flex",
						flexDirection: "column",
						padding: "24px",
						alignItems: "center",
						border: `2px solid transparent`,
						"&:hover": {
							border: `2px solid ${primaryColor}`,
							boxShadow: "0 8px 12px rgba(0,0,0,.2)",
						},
						cursor: "pointer",
					}}
					onClick={() => {
						props.handleModalOpen(props.testimonialData);
					}}
				>
					<img
						style={{
							marginBottom: "8px",
							width: "auto",
							height: "60px",
						}}
						src={props.testimonialData.logo}
						alt="Client logo"
					/>
					<Box
						sx={{
							minHeight: 73,
							fontSize: "16px",
							textAlign: "center",
							display: "flex",
							alignItems: "center",
						}}
					>
						"{props.testimonialData.heading}"
					</Box>
					<Box
						sx={{
							fontSize: "16px",
							marginTop: "4px",
							color: "#9a9b9b",
						}}
					>
						Read more
					</Box>
				</Paper>
				<Box
					sx={{
						marginTop: "16px",
						fontSize: "15px",
						textAlign: "center",
					}}
				>
					{props.testimonialData.name}
				</Box>
				<Box
					sx={{
						fontSize: "13px",
						textAlign: "center",
						color: "rgba(17, 17, 17, 0.5)",
					}}
				>
					{props.testimonialData.title}
				</Box>
			</Box>
		</>
	);
};
