import { useReducer, ReactNode } from "react";
import { initialState } from "./InitialState";
import WizardContext from "./WizardContext";
import { WizardActionType } from "./types/WizardProviderType";
import { WizardStateType } from "./types/WizardStateType";

const WizardContextReducer = (
	state: WizardStateType,
	action: WizardActionType
): WizardStateType => {
	switch (action.type) {
		case "CLOSE_WIZARD": {
			return initialState;
		}
		case "REQUEST_WIZARD": {
			return {
				...state,
				wizardUrl: action.payload.url,
				wizardRequested: true,
			};
		}
	}
};

export const WizardProvider = (props: { children: ReactNode }): JSX.Element => {
	const [state, dispatchWizard] = useReducer(
		WizardContextReducer,
		initialState
	);

	return (
		<WizardContext.Provider
			value={{
				state,
				dispatchWizard,
			}}
		>
			{props.children}
		</WizardContext.Provider>
	);
};

export default WizardProvider;
