import { FC } from "react";
import { Link } from "react-router-dom";
import { Link as MuiLink, Typography, Container, Grid } from "@mui/material";
import { Facebook, LinkedIn } from "@mui/icons-material";
import theme from "../../app/theme";
import logo from "../../img/logo.webp";
import useQualifierContext from "../../context/qualifiers/hooks/useQualifierContext";
import {
	Qualifier,
	QualifierValue,
} from "../../context/qualifiers/types/StateType";
import { useInvokeWizard } from "../../context/wizard/hooks/useInvokeWizard";
import { useFetchLevelAndProducts } from "../../context/qualifiers/hooks/useFetchLevelAndProducts";

export const Footer: FC = () => {
	const {
		state: { qualifiers },
	} = useQualifierContext();
	const productLines = qualifiers.filter((q: Qualifier) => q.level === 2)[0];

	const { invokeWizard } = useInvokeWizard();
	const { fetchNextLevelAndProducts } = useFetchLevelAndProducts();

	const setProductLine = async (id: number, level: number) => {
		fetchNextLevelAndProducts(id, level, false);
	};

	const footerLink = {
		margin: "0 auto",
		padding: 0,
		textAlign: "left",
		cursor: "pointer",
	};

	const bgColor = theme.palette.secondary.main;
	const white = theme.palette.common.white;

	return (
		<Container
			sx={{
				minWidth: "100vw",
				fontSize: "1rem",
				backgroundColor: bgColor,
				color: white,
			}}
		>
			<Grid
				container
				columns={12}
				direction="row"
				rowSpacing={0}
				columnSpacing={2}
				maxWidth="xl"
				sx={{ margin: "0 auto" }}
			>
				{/* Logo and Social Links */}
				<Grid item lg={3} md={3} sm={3} sx={{ margin: "0 auto" }}>
					<Grid container direction="column">
						<Grid item>
							<Link to="/">
								<img
									src={logo}
									alt="My Food &amp; Bev Training"
									style={{
										width: "50%",
										marginBottom: "1rem",
									}}
								/>
							</Link>
						</Grid>
						<Grid item sx={{ footerLink }}>
							<MuiLink href="https://www.facebook.com/MyFoodAndBevTraining">
								<Facebook
									sx={{
										color: white,
									}}
								/>
							</MuiLink>
							<MuiLink href="https://www.linkedin.com/company/my-food-and-bev-training">
								<LinkedIn
									sx={{
										color: white,
									}}
								/>
							</MuiLink>
						</Grid>
					</Grid>
				</Grid>

				{/* Home, Employers, Map */}
				<Grid item lg={3} sx={{ margin: "0 auto" }}>
					<Grid container direction="column">
						<Grid item sx={{ footerLink }}>
							<Link to="/">Home</Link>
						</Grid>
						<Grid item sx={{ footerLink }}>
							<Link to="/employers">Employers</Link>
						</Grid>
						<Grid item sx={{ footerLink }}>
							<Link to="/map">Map</Link>
						</Grid>
					</Grid>
				</Grid>

				{/* FAQ/Support, About, Blog */}
				<Grid item lg={3} sx={{ margin: "0 auto" }}>
					<Grid container direction="column">
						<Grid item sx={{ footerLink }}>
							<MuiLink
								className="ess-help"
								onClick={() =>
									invokeWizard(
										`${process.env.REACT_APP_TXN_URL}/helps/catalog/mst`
									)
								}
								sx={{
									color: white,
									cursor: "pointer",
								}}
								data-testid="faq"
							>
								FAQ/Support
							</MuiLink>
						</Grid>
						<Grid item sx={{ footerLink }}>
							<Link to="/about">About</Link>
						</Grid>
						<Grid item sx={{ footerLink }}>
							<a href="https://blog.myfoodandbevtraining.com">
								Blog
							</a>
						</Grid>
					</Grid>
				</Grid>

				{/* Product Lines */}
				<Grid item lg={3} sx={{ margin: "0 auto" }}>
					<Grid container direction="column">
						{productLines &&
							productLines.values.map((pl: QualifierValue) => (
								<Grid
									item
									sx={{ footerLink }}
									key={pl.id}
									onClick={() =>
										setProductLine(
											pl.id,
											productLines.level
										)
									}
								>
									<Link to={`/products/${pl.urlFragment}`}>
										{pl.value}
									</Link>
								</Grid>
							))}
					</Grid>
				</Grid>
			</Grid>

			<Typography
				textAlign="right"
				fontSize="small"
				sx={{ paddingBottom: "1rem" }}
			>
				<span style={{ fontSize: ".9rem" }}>&copy; </span>
				{new Date().getFullYear()} My State Training, a service of
				eStrategy Solutions, Inc.
			</Typography>
		</Container>
	);
};
