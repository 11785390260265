import { Star, AttachMoney, Equalizer } from "@mui/icons-material";
import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

type Feature = {
	component: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
		muiName: string;
	};
	title: string;
	description: string;
	image: string;
	hash: string;
};

export const features: Feature[] = [
	{
		component: AttachMoney,
		title: "Affordable",
		description:
			"When you purchase a course from My Food &amp; Bev Training, you have access to the course, review materials, and exam. You pay once, and everything is taken care of.",
		image: "affordable_compressed.webp",
		hash: "LBCGJg1R00;J~XJCInnh00+@?aJ:",
	},
	{
		component: Star,
		title: "Fast &amp; Easy Access",
		description:
			"Our courses are both mobile and desktop friendly, so you can access your course from anywhere, anytime.",
		image: "access_compressed.webp",
		hash: "LLNJ|8.SM|DiD$WAM|Mx_N.8V?Io",
	},
	{
		component: Equalizer,
		title: "Risk Management",
		description:
			"We provide owners &amp; managers the ability to track employee course enrollment, progress, and completion. This tool will ensure certifications are up-to-date, protecting both you &amp; your business.",
		image: "risk_management_compressed.webp",
		hash: "LPGl0I_N.8tS_3s:%MaeyXt7%Lof",
	},
];
