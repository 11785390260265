import qualy from "./axios";
import { findLegacySubservice } from "./helpers/findLegacySubservice";
import { sortQualifierBindingEntries } from "./helpers/sortQualifierBindingEntries";

const limit: string | undefined = process.env.REACT_APP_QBE_LIMIT;

const isLegacySubservice = findLegacySubservice();

const qualifierBindingApi = {
	getBindingEntriesByPoolId: async (qualifierLevelPoolId: number) => {
		if (isLegacySubservice) return;
		return qualy
			.get(`/qualifierBindingEntry`, {
				params: { qualifierLevelPoolId, limit },
			})
			.then((res) => {
				return {
					...res.data,
					items: sortQualifierBindingEntries(res.data.items),
				};
			});
	},
	getBindingEntriesByUrl: async (qualifiedUrl: string) => {
		if (isLegacySubservice) return;
		return qualy
			.get(
				`/qualifierBindingEntry/findByQualifiedUrl?qualifiedUrl=${qualifiedUrl}`,
				{
					params: { limit },
				}
			)
			.then((res) => {
				return {
					...res.data,
					items: sortQualifierBindingEntries(res.data.items),
				};
			});
	},
};

export default qualifierBindingApi;
