import { QualifierBindingEntry } from "../../context/qualifiers/types/StateType";

const sort = (arr: QualifierBindingEntry[]) => {
    // SORT BY ranking, THEN BY title
    arr.sort((a: QualifierBindingEntry, b: QualifierBindingEntry) => {
      if (a.ranking < b.ranking) return -1;
      if (a.ranking > b.ranking) return 1;
      if (a.product.title < b.product.title) return -1;
      if (a.product.title > b.product.title) return 1;
      return 0;
  });
}

export const sortQualifierBindingEntries = (values: QualifierBindingEntry[]) => {
    const reserved: QualifierBindingEntry[] = [];
    const nonReserved: QualifierBindingEntry[] = [];
    values.forEach((val: QualifierBindingEntry) => val.isReserved ? reserved.push(val) : nonReserved.push(val))
    sort(reserved);
    sort(nonReserved);
    return [ ...reserved, ...nonReserved ];
};
