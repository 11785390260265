import qualy from "./axios";
import { Decorator } from "../context/qualifiers/types/StateType";

export interface testimonialData {
	heading: string;
	body: string;
	name: string;
	title: string;
	logo: string;
}

export const decoratorApi = {
	getTestimonials: async (): Promise<testimonialData[]> => {
		return qualy
			.get(`/decorator/findByTypeAndLocation`, {
				params: {
					type: "testimonial",
					tableName: "qlfr.qualifier_type",
					rowId: 2,
				},
			})
			.then((res) =>
				(res.data as Decorator[]).map(
					(decorator) =>
						decorator.decoratorExts.reduce(
							(prev, curr) => ({
								...prev,
								[curr.key]:
									(curr.key == "logo"
										? process.env
												.REACT_APP_S3_IMAGE_BASE_URL +
										  "/subservice_logos/60px/"
										: "") + curr.value,
							}),
							{}
						) as testimonialData
				)
			);
	},
};
