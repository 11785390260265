import { State } from "./types/StateType";

/**
 * The initial state
 */
export const initialState: State = {
	isLoading: false,
	isLoadedHubspot: false,
	isDirectUrl: false,
	wizardUrl: `${process.env.REACT_APP_TXN_URL}`,
	service: {
		id: 0,
		name: "",
		parentId: 0,
		fullName: "",
		typeId: 0,
		filterLevel: 0,
		hideProductLevel: false,
	},
	qualifiedUrl: [],
	qualifiers: [
		{
			id: 1,
			level: 1,
			name: "Country",
			parent: {
				id: 0,
				level: 0,
				name: "",
				value: "",
				urlFragment: "",
				isHidden: false,
				ranking: 0,
				isReserved: false,
			},
			values: [
				{
					id: 1,
					level: 1,
					name: "Country",
					value: "USA",
					urlFragment: "usa",
					isReserved: false,
					isHidden: false,
					ranking: 1,
					selected: true,
				},
			],
		},
	],
	products: {
		meta: {
			currentPage: 0,
			itemCount: 0,
			itemsPerPage: 0,
			totalItems: 0,
			totalPages: 0,
		},
		items: [],
		links: {
			first: "",
			previous: "",
			next: "",
			last: "",
		},
	},
};
