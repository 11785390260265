import { Dispatch } from "react";
import { QualifierActionType } from "../../context/qualifiers/types/QualifierProviderType";
import qualifierLevelApi from "../qualifierLevel.api";
import { findLegacySubservice } from "./findLegacySubservice";

const isLegacySubservice = findLegacySubservice();

export const fetchNextLevel = async (
	id: number,
	dispatch: Dispatch<QualifierActionType>,
	isDirectUrl: boolean,
	getDecorators: boolean,
	tableName?: string,
	rowId?: number,
	hideProductLevel?: boolean
) => {
	if (isLegacySubservice) return;
	try {
		dispatch({ type: "SET_DIRECT_URL", payload: isDirectUrl });
		let payload = await qualifierLevelApi.getQualifiersByParentId(
			id,
			getDecorators,
			tableName,
			rowId
		);
		if (hideProductLevel)
			payload = await qualifierLevelApi.getQualifiersByParentId(
				payload.values[0].id,
				getDecorators,
				tableName,
				rowId
			);

		dispatch({
			type: "ADD_NEXT_QUALIFIER_LEVEL",
			payload,
		});
		return payload;
	} catch (error: any) {
		let message = "Unknown Error";
		if (error instanceof Error) {
			message = error.message;
		}
		console.error(message)
	}
};
