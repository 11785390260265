import { Container, Grid, Typography } from "@mui/material";
import { Footer } from "../../components/Footer";
import { Navbar } from "../../components/Navbar";
import { Products } from "../../components/Products";
import { Qualifiers } from "../../components/Qualifiers";
import { navItems } from "../navItems";

const fontFamily = "Roboto, Helvetica Neue, Helvetica, Arial, sans-serif";
const footerTextColor = "white";
const primaryColor = "#a81d3f";
const secondaryColor = "#28a745";
const backgroundImage = `url(${process.env.REACT_APP_S3_IMAGE_BASE_URL}/bjs/bjs_exterior2.jpg)`;

const Home = () => {
	return (
		<>
			<Grid container columns={12} sx={{ fontFamily }}>
				<Grid item lg={12} md={12} sm={12} xs={12}>
					<Navbar
						navItems={navItems}
						backgroundColor={primaryColor}
						service="bjs"
						logoUrl="bjs_logo_200px.png"
					/>
				</Grid>
				<Grid
					item
					lg={12}
					md={12}
					sm={12}
					xs={12}
					sx={{
						backgroundImage,
						backgroundRepeat: "no-repeat",
						backgroundSize: "cover",
						backgroundPosition: "50% 37%",
						mx: "auto",
						height: 150,
						top: 0,
						left: 0,
					}}
				/>
				<Grid
					item
					lg={12}
					md={12}
					sm={12}
					xs={12}
					sx={{
						minHeight: "50vh",
						mx: "auto",
					}}
				>
					<Grid container columns={12}>
						<Grid
							item
							xl={6}
							lg={8}
							md={8}
							sm={10}
							xs={12}
							sx={{
								mx: "auto",
								my: "30px",
								backgroundColor: "rgba(255,255,255,0.9)",
								padding: "30px",
								borderRadius: "10px",
							}}
						>
							<Container sx={{ mb: 6, textAlign: "center" }}>
								<Typography
									variant="h5"
									fontWeight="bold"
									textTransform="uppercase"
								>
									Required Training for BJ&#39;s Associates
								</Typography>
								<Typography variant="h6">
									Register. Complete Your Training. Download
									Your Certificate.
								</Typography>
							</Container>
							<Qualifiers />
							<Products
								primaryColor={primaryColor}
								secondaryColor={secondaryColor}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item lg={12} md={12} sm={12} xs={12}>
					<Footer
						backgroundColor={primaryColor}
						color={footerTextColor}
					/>
				</Grid>
			</Grid>
		</>
	);
};

export default Home;
