import {
	Box,
	Container,
	Grid,
	Theme,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import { Footer } from "../../components/Footer";
import { Navbar } from "../../components/Navbar";
import { Products } from "../../components/Products";
import { Qualifiers } from "../../components/Qualifiers";
import { navItems } from "../navItems";
import services from "../../../../service.map";
const fontFamily = "Roboto, Helvetica Neue, Helvetica, Arial, sans-serif";
const footerTextColor = "#DDD";
const textColor = "#888";
const primaryColor = "white";
const secondaryColor = "#888";
const backgroundColor = "#1d536e";
const footerBackgroundColor = "#3b3838";
const productSecondaryColor = "#1d6e68";
const heroImage = `${process.env.REACT_APP_S3_IMAGE_BASE_URL}/apss/aplus-hero.jpg`;
const backgroundImage = `url(${process.env.REACT_APP_S3_IMAGE_BASE_URL}/apss/aplus-hero.jpg)`;
const divider = `${process.env.REACT_APP_S3_IMAGE_BASE_URL}/apss/divider.png`;
const logo = `${process.env.REACT_APP_S3_IMAGE_BASE_URL}/apss/aplus-logo-white.png`;
const simpleDivider = `${process.env.REACT_APP_S3_IMAGE_BASE_URL}/apss/simple-divider.png`;

const DesktopBanner = () => {
	return (
		<>
			<Grid
				container
				sx={{
					backgroundImage,
					backgroundBlendMode: "multiply",
					backgroundRepeat: "no-repeat",
					backgroundSize: "cover",
					backgroundColor,
					backgroundPosition: "50% 30%",
					mx: "auto",
					height: 250,
					top: 0,
					left: 0,
					borderBottom: `20px solid ${footerBackgroundColor}`,
					justifyContent: "center",
				}}
			>
				<Box
					sx={{
						minWidth: 200,
						height: 250,
						display: "flex",
						alignItems: "center",
						justifyContent: "right",
						marginRight: "4em",
					}}
				>
					<Box>
						<img src={logo} alt="aplus-logo" />
					</Box>
				</Box>
				<Box>
					<img
						src={heroImage}
						alt="aplus-hero"
						style={{
							clipPath: "circle(54% at 50% 50%)",
							width: 360,
							height: "auto",
						}}
					/>
				</Box>
				<Box
					sx={{
						height: 250,
						width: 200,
						display: "flex",
						alignItems: "center",
						flexDirection: "column",
						justifyContent: "center",
						marginLeft: "3em",
						marginRight: "1em",
					}}
				>
					<img src={divider} alt="aplus-logo" />
					<Typography
						variant="h5"
						fontWeight="bold"
						textTransform="uppercase"
						color="white"
						textAlign="center"
						marginTop="0.5em"
					>
						EXCELLENCE IN
					</Typography>
					<Typography
						variant="h5"
						fontWeight="bold"
						textTransform="uppercase"
						color="white"
						textAlign="center"
						marginBottom="0.5em"
					>
						STAFFING
					</Typography>
					<img src={simpleDivider} alt="aplus-logo" />
				</Box>
			</Grid>
			<Grid
				item
				lg={12}
				md={12}
				sm={12}
				xs={12}
				sx={{
					minHeight: "50px",
					mx: "auto",
				}}
			/>
		</>
	);
};

const MobileBanner = () => {
	return (
		<Grid
			container
			sx={{
				backgroundImage,
				backgroundBlendMode: "multiply",
				backgroundRepeat: "no-repeat",
				backgroundSize: "cover",
				backgroundColor,
				backgroundPosition: "50% 30%",
				mx: "auto",
				height: 400,
				top: 0,
				left: 0,
				borderBottom: "20px solid #3b3838",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				textAlign: "center",
			}}
		>
			<Container sx={{ marginTop: "1em" }}>
				<img
					src={logo}
					alt="aplus-logo"
					style={{
						width: "auto",
					}}
				/>
			</Container>

			<Container sx={{ height: "60%", paddingTop: "2em" }}>
				<img
					src={divider}
					alt="aplus-logo"
					style={{
						width: "auto",
						marginBottom: "1em",
					}}
				/>
				<Typography
					variant="h5"
					fontWeight="bold"
					textTransform="uppercase"
					color="white"
					marginBottom="0.25em"
				>
					EXCELLENCE IN
				</Typography>
				<Typography
					variant="h5"
					fontWeight="bold"
					textTransform="uppercase"
					color="white"
				>
					STAFFING
				</Typography>
				<img
					src={simpleDivider}
					alt="aplus-logo"
					style={{
						width: "auto",
						marginTop: "1.5em",
					}}
				/>
			</Container>
		</Grid>
	);
};

const Home = () => {
	const theme: Theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("md"));

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				minHeight: "100vh",
			}}
		>
			<Grid container columns={12} sx={{ fontFamily }}>
				<Grid item lg={12} md={12} sm={12} xs={12}>
					<Navbar
						color={textColor}
						navItems={navItems}
						backgroundColor={primaryColor}
						service="apss"
						servicePath="aplus"
						logoUrl="aplus-logo.png"
						hamburgerColor={textColor}
						filterLevel={services.aplus.filterLevel! + 1}
						parentId={services.aplus.parentId}
					/>
				</Grid>
				{isMobile ? <MobileBanner /> : <DesktopBanner />}
				<Grid
					item
					lg={12}
					md={12}
					sm={12}
					xs={12}
					sx={{
						minHeight: "50vh",
						mx: "auto",
					}}
				>
					<Grid container columns={12}>
						<Grid
							item
							lg={6}
							md={8}
							sm={10}
							xs={11}
							sx={{
								mx: "auto",
								my: "30px",
								backgroundColor: "rgba(255,255,255,0.9)",
								padding: "30px",
								borderRadius: "10px",
							}}
						>
							<Qualifiers />
							<Products
								primaryColor={backgroundColor}
								secondaryColor={productSecondaryColor}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid
					item
					lg={12}
					md={12}
					sm={12}
					xs={12}
					sx={{ marginTop: "auto" }}
				></Grid>
			</Grid>
			<Box sx={{ marginTop: "auto" }}>
				<Footer
					backgroundColor={footerBackgroundColor}
					color={footerTextColor}
				/>
			</Box>
		</Box>
	);
};

export default Home;
