import {
	Box,
	Button,
	Grid,
	Theme,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import { useInvokeWizard } from "../../../../context/wizard/hooks/useInvokeWizard";

const E = process.env;

interface ProductType {
	apiKey: string;
	title: string;
	license: string;
}

interface ProductProps {
	product: ProductType;
}

const productsForAssistants: ProductType[] = [
	{
		apiKey: "mst/mfbt-exxm-ss-fdhn-pkg",
		title: "BOTH SELLER/SERVER + FOOD HANDLER TRAINING",
		license: "TABC & DSHS APPROVED",
	},
	{
		apiKey: "mst/mst-exxm-seller-server",
		title: "TABC SELLER/SERVER TRAINING",
		license: "TABC APPROVED",
	},
	{
		apiKey: "mst/mst-exxm-food-handlers",
		title: "TEXAS FOOD HANDLER TRAINING",
		license: "DSHS APPROVED",
	},
];

const productsForManagers: ProductType[] = [
	{
		apiKey: "tnt/mst-sfs-exxm-tx-fm-bndl",
		title: "Texas FOOD MANAGER COURSE + CERTIFICATION",
		license: "EXAM. DSHS APPROVED",
	},
	{
		apiKey: "tnt/mst-sfs-exxm-tx-fm-exam",
		title: "TEXAS FOOD MANAGER CERTIFICATION EXAM",
		license: "DSHS APPROVED",
	},
];

function ProductDetails({ product }: ProductProps) {
	return (
		<Box sx={{ fontSize: "16px" }}>
			<Typography sx={{ fontWeight: "bold" }}>{product.title}</Typography>
			<Typography>{product.license}</Typography>
		</Box>
	);
}

function ProductButton({ product }: ProductProps) {
	const theme: Theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const { invokeWizard } = useInvokeWizard();
	const wizardUrl = `${process.env.REACT_APP_TXN_URL}/wizard/${product.apiKey}`;

	return (
		<Box
			sx={{
				display: "flex",
				justifyItems: "center",
				textAlign: "center",
				justifyContent: "center",
			}}
		>
			<Button
				onClick={() => invokeWizard(`${wizardUrl}/product-purchase`)}
				sx={{
					maxWidth: isMobile ? "auto" : "114px",
					width: isMobile ? "100%" : "auto",
					fontSize: "16px",
					color: "#fff",
					backgroundColor: "#222",
					margin: "1rem",
					"&:hover": {
						backgroundColor: "#fff",
						color: "#222",
					},
				}}
			>
				Enroll Now!
			</Button>
		</Box>
	);
}

function Product({ product }: ProductProps) {
	return (
		<Box
			sx={{
				backgroundColor: "#b42f25",
				color: "#fff",
				marginTop: "1rem",
				padding: "1rem 1rem",
			}}
		>
			<Grid container>
				<Grid item xs={12} sm={8}>
					<ProductDetails product={product} />
				</Grid>
				<Grid item xs={12} sm={4}>
					<ProductButton product={product} />
				</Grid>
			</Grid>
		</Box>
	);
}

export default function Products() {
	const theme: Theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				margin: "0 3rem",
			}}
		>
			<Box
				sx={{
					width: "100%",
					maxWidth: "840px",
				}}
			>
				<Box
					sx={{
						marginTop: "2rem",
						backgroundColor: "#FBCA03",
						border: "4px solid #BB0301",
						mx: "auto",
						height: isMobile ? "auto" : 40,
						textAlign: "center",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Typography
						variant="h5"
						sx={{
							textAlign: "center",
							color: "#808080",
							fontWeight: "bold",
						}}
					>
						For CSR&apos;s & Assistant Managers
					</Typography>
				</Box>
				{productsForAssistants.map((product, index) => (
					<Grid item key={index} minWidth={"100%"}>
						<Product product={product} />
					</Grid>
				))}
				<Box
					sx={{
						marginTop: "2rem",
						backgroundColor: "#FBCA03",
						border: "4px solid #BB0301",
						mx: "auto",
						height: isMobile ? "auto" : 40,
						textAlign: "center",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Typography
						variant="h5"
						sx={{
							textAlign: "center",
							color: "#808080",
							fontWeight: "bold",
						}}
					>
						For Store Managers
					</Typography>
				</Box>
				{productsForManagers.map((product, index) => (
					<Grid item key={index} minWidth={"100%"}>
						<Product product={product} />
					</Grid>
				))}
			</Box>
		</Box>
	);
}
