import { Grid } from "@mui/material";
import { Footer } from "../../components/Footer";
import { Navbar } from "../../components/Navbar";
import { Products } from "../../components/Products";
import { Qualifiers } from "../../components/Qualifiers";
import { navItems } from "../navItems";
import { CallToAction } from "../../components/CallToAction";
import useQualifierContext from "../../../../context/qualifiers/hooks/useQualifierContext";

const Home = () => {
	const {
		state: {
			service: {
				name,
				fullName,
				path,
				logoUrl,
				bannerImage,
				colorPrimary,
				colorSecondary,
			},
		},
	} = useQualifierContext();

	const fontFamily = "Roboto, Helvetica Neue, Helvetica, Arial, sans-serif";
	const colorWhite = "var(--color-white)";
	const colorBlack = "var(--color-black)";

	return (
		<>
			<Grid
				container
				columns={12}
				sx={{ fontFamily, maxWidth: "80%", mx: "auto" }}
			>
				<Grid item lg={12} md={12} sm={12} xs={12}>
					{logoUrl && (
						<Navbar
							navItems={navItems}
							backgroundColor={colorWhite}
							color={colorBlack}
							hamburgerColor={colorPrimary}
							logoUrl={logoUrl}
							service={name}
						/>
					)}
				</Grid>
				<Grid
					item
					lg={12}
					md={12}
					sm={12}
					xs={12}
					sx={{
						backgroundImage: bannerImage,
						backgroundRepeat: "no-repeat",
						backgroundSize: "cover",
						backgroundPosition: "center 35%",
						mx: "auto",
						height: 250,
						top: 0,
						left: 0,
					}}
				/>
				<Grid
					item
					lg={12}
					md={12}
					sm={12}
					xs={12}
					sx={{
						minHeight: "50vh",
						mx: "auto",
					}}
				>
					<Grid container columns={12}>
						<Grid
							item
							lg={8}
							md={8}
							sm={10}
							xs={11}
							sx={{
								mx: "auto",
								my: "30px",
								backgroundColor: "rgba(255,255,255,0.9)",
								padding: "30px",
								borderRadius: "10px",
							}}
						>
							<CallToAction serviceFullName={fullName ?? ""} />
							<Qualifiers />
							<Products
								primaryColor={colorPrimary ?? ""}
								secondaryColor={colorSecondary ?? ""}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item lg={12} md={12} sm={12} xs={12}>
					<Footer backgroundColor={colorWhite} color={colorBlack} />
				</Grid>
			</Grid>
		</>
	);
};

export default Home;
