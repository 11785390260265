import { NavItemsType } from "../components/Navbar/types/NavItems";

const { REACT_APP_PORTAL_URL, REACT_APP_TXN_URL } = process.env;

export const navItems: NavItemsType = [
	{
		name: "Home",
		url: "/circlek",
	},
	{
		name: "Log In",
		url: `${REACT_APP_PORTAL_URL}/mst/login`,
	},
	{
		name: "FAQ/Support",
		url: `${REACT_APP_TXN_URL}/helps/catalog/mst`,
		class: "ess-help",
	},
	{
		name: "My Account",
		class: "subservice-nav-item my-account",
		isDropdown: true,
		dropdownItems: [
			{
				name: "Login",
				url: `${REACT_APP_PORTAL_URL}/mst/login`,
			},
			{
				name: "My Course History",
				url: `${REACT_APP_TXN_URL}/activities/mst/history`,
				useWizard: true,
			},
			{
				name: "Forgot Password",
				url: `${REACT_APP_PORTAL_URL}/mst/password/reset`,
				useWizard: true,
			},
			{
				name: "Forgot Login ID",
				url: `${REACT_APP_PORTAL_URL}/mst/login/reset`,
				useWizard: true,
			},
		],
	},
];
