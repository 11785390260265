export type NavItemsType = {
	name: string;
	linkTo?: string;
	url?: string;
	isDropdown?: boolean;
	class?: string;
}[];

export const navItems: NavItemsType = [
	{
		name: "Home",
		linkTo: "/",
	},
	{
		name: "Products",
		isDropdown: true,
	},
	{
		name: "Employers",
		linkTo: "/employers",
	},
	{
		name: "Map",
		linkTo: "/map",
	},
	{
		name: "FAQ/Support",
		url: `${process.env.REACT_APP_TXN_URL}/helps/catalog/mst`,
		class: "ess-help",
	},
	{
		name: "Log In",
		url: `${process.env.REACT_APP_PORTAL_URL}/mst/login`,
	},
	{
		name: "About",
		linkTo: "/about",
	},
	{
		name: "Blog",
		url: "https://blog.myfoodandbevtraining.com",
	},
];
