import {
	AppBar,
	Box,
	Toolbar,
	Container,
	useTheme,
	useMediaQuery,
	Theme,
} from "@mui/material";

import { MobileMenu } from "./MobileMenu";
import { DesktopMenu } from "./DesktopMenu";
import { NavBarProps } from "./types/NavbarProps";

export const LegacyNavbar = ({
	navItems,
	backgroundColor,
	color,
	service,
	logoUrl,
	logoMaxWidth,
	hamburgerColor,
	servicePath,
}: NavBarProps) => {
	const theme: Theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

	return (
		<AppBar sx={{ backgroundColor, color, position: "relative", }}>
			<Container sx={{ marginBottom: 0 }}>
				<Toolbar
					disableGutters
					sx={{
						display: "flex",
						flexFlow: "row wrap",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Box
						maxWidth={logoMaxWidth ?? "80px"}
						sx={{ marginLeft: "2rem", marginRight: "2rem" }}
					>
						<a href={servicePath ? `/${servicePath}` : `/${service}`}>
							<img
								src={`${process.env.REACT_APP_S3_IMAGE_BASE_URL}/${service}/${logoUrl}`}
								alt={service}
								style={{ height: '80px', width: 'auto' }}

							/>
						</a>
					</Box>

					{isMobile ? (
						<MobileMenu
							navItems={navItems}
							hamburgerColor={hamburgerColor}
						/>
					) : (
						<DesktopMenu
							navItems={navItems}
						/>
					)}
				</Toolbar>
			</Container>
		</AppBar>
	);
};
