import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Drawer,
	IconButton,
	List,
	ListItem,
	Tooltip,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { MenuProps } from "../types/MenuProps";
import { useInvokeWizard } from "../../../context/wizard/hooks/useInvokeWizard";

export const MobileMenu = ({
	navItems,
	productLines,
	getQualifiersByParentId,
}: MenuProps) => {
	const { invokeWizard } = useInvokeWizard();

	const [openDrawer, setOpenDrawer] = useState<boolean>(false);
	const [isExpanded, setIsExpanded] = useState<boolean>(false);

	const toggleAccordian = () => setIsExpanded(!isExpanded);
	const toggleDrawer = () => setOpenDrawer(!openDrawer);
	const closeDrawer = () => setOpenDrawer(false);

	const setProductLineAndCloseNavMenu = (id: number, level: number) => {
		getQualifiersByParentId(id, level);
		closeDrawer();
	};

	return (
		<>
			<Drawer open={openDrawer} onClose={closeDrawer} anchor="right">
				<List data-testid="mobileMenu">
					{navItems &&
						navItems.map((page, index) => (
							<div key={index}>
								{page.linkTo && (
									<Link
										data-testid="menuItem"
										to={page.linkTo}
										onClick={closeDrawer}
									>
										<ListItem data-testid="listItem">
											{page.name}
										</ListItem>
									</Link>
								)}
								{page.url && !page.class && (
									<a href={page.url} onClick={closeDrawer}>
										<ListItem>{page.name}</ListItem>
									</a>
								)}
								{page.class && (
									<button
										className="ess-help"
										onClick={() =>
											invokeWizard(
												`${process.env.REACT_APP_TXN_URL}/helps/catalog/mst`
											)
										}
										data-testid="faq"
										style={{}}
									>
										<ListItem>{page.name}</ListItem>
									</button>
								)}
								{page.isDropdown && (
									<Tooltip title="Select product line">
										<Accordion
											expanded={isExpanded}
											onClick={toggleAccordian}
										>
											<AccordionSummary
												expandIcon={<ExpandMoreIcon />}
												aria-controls="panel-content"
												id="panel-header"
											>
												<ListItem>{page.name}</ListItem>
											</AccordionSummary>
											<AccordionDetails>
												{productLines &&
													productLines.values.map(
														(pl, index) => (
															<Link
																to={`/products/${pl.urlFragment}`}
																key={index}
																onClick={() =>
																	setProductLineAndCloseNavMenu(
																		pl.id,
																		productLines.level
																	)
																}
															>
																<ListItem>
																	{pl.value}
																</ListItem>
															</Link>
														)
													)}
											</AccordionDetails>
										</Accordion>
									</Tooltip>
								)}
							</div>
						))}
				</List>
			</Drawer>
			<IconButton onClick={toggleDrawer} data-testid="hamburger">
				<MenuIcon />
			</IconButton>
		</>
	);
};
