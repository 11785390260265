// PHASE 2:
// TODO: ZOOM IN/OUT ON MAP. MOVE MAP AROUND.
// POSSIBLY CLICK ON STATE AND POPUP PRODUCT OFFERING IN THAT STATE.
// Example of a responsive map with zoom and state focus:
// http://codewritingcow.com/d3-js/maps/americas/united-states/
// https://observablehq.com/@d3/zoom-to-bounding-box
// https://gist.github.com/michellechandra/0b2ce4923dc9b5809922

import { Container, Divider } from "@mui/material";

export const Map = () => {
	return (
		<Container maxWidth={"xl"}>
			<Container maxWidth={"md"}>
				<img
					src={`${process.env.REACT_APP_S3_IMAGE_BASE_URL}/coverage_map_compressed.webp`}
					alt="US Map"
				/>
			</Container>
			<Divider />
		</Container>
	);
};
