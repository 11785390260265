import axios from "axios";
import findService from "../context/helpers/findService";

const env = process.env;

const service = findService(window.location.pathname.split("/")[1]);

const qualy = axios.create({
	baseURL: `${env.REACT_APP_QUALY_BASE_URL}`,
	headers: {
		"X-API-KEY": `${env.REACT_APP_API_KEY}`,
		"X-SERVICE": service?.id ?? "",
	},
});

export default qualy;
