import { useCallback, useEffect } from "react";
import {
	AppBar,
	Box,
	Toolbar,
	Container,
	useTheme,
	useMediaQuery,
	Theme,
} from "@mui/material";

import { MobileMenu } from "./MobileMenu";
import { DesktopMenu } from "./DesktopMenu/DesktopMenu";
import { Qualifier } from "../../../../context/qualifiers/types/StateType";
import { fetchNextLevel } from "../../../../api/helpers/fetchNextLevel";
import { fetchProductsById } from "../../../../api/helpers/fetchProductsById";
import useQualifierContext from "../../../../context/qualifiers/hooks/useQualifierContext";
import { NavBarProps } from "./types/NavbarProps";
import { getLevelPoolId } from "../../../../api/helpers/getLevelPoolId";
import { useLocation } from "react-router-dom";
import services from "../../../../service.map";

export const Navbar = ({
	navItems,
	backgroundColor,
	color,
	service,
	logoUrl,
	logoMaxWidth,
	hamburgerColor,
	servicePath,
	filterLevel,
	parentId,
}: NavBarProps) => {
	const theme: Theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

	const {
		state: { qualifiers },
		dispatch,
	} = useQualifierContext();
	const customFilterLevel = filterLevel ?? 2;
	const states: Qualifier = qualifiers.filter(
		(q: Qualifier) => q.level === customFilterLevel
	)[0];

	const { pathname } = useLocation();

	useEffect(() => {
		if (qualifiers.length <= 1 && parentId) {
			fetchNextLevel(parentId, dispatch, false, true);
		}
	}, [pathname]);

	const getQualifiersByParentId = useCallback(
		async (id: number, level: number) => {
			dispatch({ type: "RESET_PRODUCTS" });
			dispatch({ type: "SET_DIRECT_URL", payload: false });
			dispatch({
				type: "SELECT_QUALIFIER_VALUE",
				payload: { id, level },
			});
			const levelPoolId = await getLevelPoolId(Number(id), true);
			fetchNextLevel(id, dispatch, false, false);
			fetchProductsById(levelPoolId, dispatch);
		},
		[]
	);

	return (
		<AppBar sx={{ backgroundColor, color, position: "relative" }}>
			<Container sx={{ marginBottom: 0 }}>
				<Toolbar
					disableGutters
					sx={{
						display: "flex",
						flexFlow: "row wrap",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Box
						maxWidth={logoMaxWidth ?? "80px"}
						sx={{ marginLeft: "2rem", marginRight: "2rem" }}
					>
						<a
							href={
								servicePath ? `/${servicePath}` : `/${service}`
							}
						>
							{logoUrl ? (
								<img
									src={`${process.env.REACT_APP_S3_IMAGE_BASE_URL}/${service}/${logoUrl}`}
									alt={service}
								/>
							) : (
								services[service].fullName
							)}
						</a>
					</Box>

					{isMobile ? (
						<MobileMenu
							navItems={navItems}
							qualifier={states}
							getQualifiersByParentId={getQualifiersByParentId}
							hamburgerColor={hamburgerColor}
						/>
					) : (
						<DesktopMenu
							navItems={navItems}
							qualifier={states}
							getQualifiersByParentId={getQualifiersByParentId}
						/>
					)}
				</Toolbar>
			</Container>
		</AppBar>
	);
};
