import { Container, Typography } from "@mui/material";

interface CallToActionProps {
	serviceFullName: string;
}

export const CallToAction = ({ serviceFullName }: CallToActionProps) => {
	return (
		<Container sx={{ mb: 6, textAlign: "center" }}>
			<Typography
				variant="h5"
				fontWeight="bold"
				textTransform="uppercase"
			>
				Required Training for {serviceFullName}&#39;s Associates
			</Typography>
			<Typography variant="h6">
				Register. Complete Your Training. Download Your Certificate.
			</Typography>
		</Container>
	);
};
