import { FC, useEffect } from "react";
import { Theme, Typography, useMediaQuery, useTheme } from "@mui/material";
import "./styles/hubspot.css";

interface HubspotProps {
	loaded: boolean;
}
declare let window: any;
const createHubspot = () => {
	const script = document.createElement("script");
	script.id = "hbsptScript";
	script.src = "https://js.hsforms.net/forms/v2.js";
	if (!document.querySelector("#hbsptScript")) {
		document.body.appendChild(script);
	}
	if (window.hbspt) {
		const E = process.env;
		return window.hbspt.forms.create({
			region: E.REACT_APP_HUBSPOT_REGION || "",
			portalId: E.REACT_APP_HUBSPOT_PORTAL_ID || "",
			formId: E.REACT_APP_HUBSPOT_FORM_ID || "",
			target: "#hubspotForm",
		});
	} else {
		setTimeout(createHubspot, 500);
	}
};

export const Hubspot: FC = () => {
	useEffect(() => {
		if (document.querySelector("#hbsptScript")) {
			document.querySelector("#hbsptScript")?.remove();
		}
		createHubspot();
	}, [window.hbspt]);

	const theme: Theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("md"));
	return (
		<>
			<div>
				<div className="general-title">
					<Typography
						variant="h6"
						textAlign="center"
						sx={{ padding: "1rem 3rem" }}
					>
						Need to get your team trained?
						<br />
						Bulk Pricing, No Contracts, No Hassle. Let&#39;s Talk.
					</Typography>
				</div>

				<div
					id="hubspotForm"
					className={`hubspot ${
						isMobile ? "hubspotMobile" : "hubspotDesktop"
					}`}
				></div>
			</div>
		</>
	);
};
