import {
	Box,
	Grid,
	Theme,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";

export default function ProductsDescriptions() {
	const theme: Theme = useTheme();
	const isLarge = useMediaQuery(theme.breakpoints.up("lg"));
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	const containerGridStyles = {
		display: "flex",
		paddingLeft: isMobile ? "1em" : "6em",
		paddingRight: isMobile ? "1em" : "6em",
		maxWidth: isLarge ? "80%" : "auto",
		margin: "auto",
	};
	const itemGridStyles = {
		paddingLeft: "2em",
		paddingRight: "2em",
	};

	const typographyTitleStyles = {
		color: "black",
		paddingTop: "0.5em",
		paddingBottom: "0.5em",
	};

	const typographyLicenseStyles = {
		fontWeight: "bold",
		color: "#686868",
		paddingTop: "0.5em",
		paddingBottom: "0.5em",
	};

	const typographyStyles = {
		fontSize: "16px",
		color: "#686868",
		paddingTop: "0.5em",
		paddingBottom: "0.5em",
	};

	return (
		<Box sx={{
			marginTop: '3rem'
		}}>
			<Grid
				container
				sx={{
					...containerGridStyles,
				}}
			>
				<Grid
					item
					xs={12}
					md={6}
					sx={{
						...itemGridStyles,
					}}
				>
					<Typography variant="h5" sx={{ ...typographyTitleStyles }}>
						TEXAS{" "}
						<span style={{ color: "#AD1515" }}>SELLER/SERVER</span>{" "}
						COURSE INFO
					</Typography>
					<Typography
						variant="h6"
						sx={{ ...typographyLicenseStyles }}
					>
						TABC License # 617-695
					</Typography>
					<Typography sx={{ ...typographyStyles }}>
						<span style={{ fontWeight: "bold" }}>
							Required for all Exxpress Mart store employees.
						</span>{" "}
						Our{" "}
						<span style={{ fontWeight: "bold" }}>
							TABC approved
						</span>{" "}
						Seller/Server course is valid statewide and will get you
						certified to responsibly sell alcohol in no time! We
						even offer a free second chance to pass the
						TABC-mandated exam if you need it. Upon successful
						completion, you can download your TABC certificate
						immediately.
					</Typography>
				</Grid>
				<Grid
					item
					xs={12}
					md={6}
					sx={{
						...itemGridStyles,
					}}
				>
					<Typography variant="h5" sx={{ ...typographyTitleStyles }}>
						TEXAS{" "}
						<span style={{ color: "#AD1515" }}>
							FOOD SAFETY MANAGER
						</span>{" "}
						TRAINING
					</Typography>
					<Typography
						variant="h6"
						sx={{ ...typographyLicenseStyles }}
					>
						DSHS License # 10025
					</Typography>
					<Typography sx={{ ...typographyStyles }}>
						<span style={{ fontWeight: "bold" }}>
							This food safety training course is OPTIONAL for all
							Exxpress Mart store managers, relief assistants and
							trainers.
						</span>{" "}
						This training program is{" "}
						<span style={{ fontWeight: "bold" }}>
							TX DSHS approved
						</span>{" "}
						and will prepare you to take the required Texas Food
						Protection Manager Certification Exam. You may view this
						course as many times as you like. The course completion
						certificate does NOT substitute for the certification
						exam. The course assessment is designed to simulate the
						real certification exam experience. It will help you
						identify areas of strength and weakness before you sit
						for the actual food manager certification exam! You can
						take this course assessment two times.
					</Typography>
				</Grid>
			</Grid>
			<Grid
				container
				sx={{
					...containerGridStyles,
				}}
			>
				<Grid
					item
					xs={12}
					md={6}
					sx={{
						...itemGridStyles,
					}}
				>
					<Typography variant="h5" sx={{ ...typographyTitleStyles }}>
						TEXAS{" "}
						<span style={{ color: "#AD1515" }}>FOOD HANDLER</span>{" "}
						COURSE INFO
					</Typography>
					<Typography
						variant="h6"
						sx={{ ...typographyLicenseStyles }}
					>
						DSHS License # 162
					</Typography>
					<Typography sx={{ ...typographyStyles }}>
						<span style={{ fontWeight: "bold" }}>
							Required for Exxpress Mart part-time and full-time
							clerks, clerk trainers and facilitators.
						</span>{" "}
						With our{" "}
						<span style={{ fontWeight: "bold" }}>
							TX DSHS approved
						</span>{" "}
						Texas Food Handlers course, you will learn how to avoid
						common mistakes in food preparation and handling that
						can lead to foodborne illnesses. There's no final exam
						in our course, so all you have to do is complete the
						course, download your certificate, and start working!
						It&apos;s valid statewide.
					</Typography>
				</Grid>
				<Grid
					item
					xs={12}
					md={6}
					sx={{
						...itemGridStyles,
					}}
				>
					<Typography variant="h5" sx={{ ...typographyTitleStyles }}>
						TEXAS{" "}
						<span style={{ color: "#AD1515" }}>
							FOOD PROTECTION MANAGER CERTIFICATION
						</span>{" "}
						EXAM
					</Typography>
					<Typography
						variant="h6"
						sx={{ ...typographyLicenseStyles }}
					>
						DSHS License # 10025
					</Typography>
					<Typography sx={{ ...typographyStyles }}>
						<span style={{ fontWeight: "bold" }}>
							Required for all Exxpress Mart store managers,
							relief assistants and trainers
						</span>{" "}
						this online exam is{" "}
						<span style={{ fontWeight: "bold" }}>
							TX DSHS accredited
						</span>{" "}
						and is valid statewide. Passing the Texas Food
						Protection Manager Certification Exam will indicate that
						you have the knowledge, skills, and abilities necessary
						to oversee the safe storage, preparation, and service of
						food in your store.
					</Typography>
				</Grid>
			</Grid>
		</Box>
	);
}
