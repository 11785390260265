import { FC, useEffect, useState } from "react";
import {
	Container,
	Grid,
	Card,
	CardContent,
	Icon,
	Typography,
} from "@mui/material";
import { features } from "./features";
import parse from "html-react-parser";
import { primaryColor } from "../../app/theme";
import { BlurhashCanvas } from "react-blurhash";

export const Features: FC = () => {
	const colorRed = { color: primaryColor };

	const [imagesLoaded, setImagesLoaded] = useState(
		Array(features.length).fill(false)
	);

	useEffect(() => {
		features.forEach((feature, index) => {
			const img = new Image();
			img.onload = () => {
				if (imagesLoaded[index]) return;
				const newImagesLoaded = [...imagesLoaded];
				newImagesLoaded[index] = true;
				setImagesLoaded(newImagesLoaded);
			};
			img.src = `${process.env.REACT_APP_S3_IMAGE_BASE_URL}/${feature.image}`;
		});
	}, [imagesLoaded]);

	return (
		<>
			<Container sx={{ margin: "5rem auto" }}>
				<Typography variant="h5" textAlign="center">
					What sets My Food <span style={colorRed}>&amp;</span> Bev
					Training apart?
				</Typography>

				<Grid
					container
					columns={12}
					direction="row"
					rowSpacing={1}
					maxWidth="lg"
				>
					{features.map((feature, index) => (
						<Grid item lg={3} sm={8} xs={12} key={feature.image}>
							<Card
								sx={{
									display: "flex",
									flexFlow: "column wrap",
									alignItems: "center",
									justifyContent: "space-between",
									minHeight: "500px",
									boxShadow: "0 5px 15px rgba(0,0,0,.4)",
									borderRadius: "15px",
									transition: "all .2s ease",
								}}
							>
								<CardContent sx={{ textAlign: "center" }}>
									<Icon component={feature.component}></Icon>
									<Typography
										variant="h6"
										textAlign="center"
										sx={{ margin: "10px auto" }}
									>
										{parse(feature.title)}
									</Typography>
									<p
										style={{
											fontSize: ".8rem",
											lineHeight: 1.35,
											textAlign: "justify",
										}}
									>
										{parse(feature.description)}
									</p>
								</CardContent>
								{imagesLoaded[index] ? (
									<img
										role="presentation"
										decoding="async"
										src={`${process.env.REACT_APP_S3_IMAGE_BASE_URL}/${feature.image}`}
										alt={feature.image}
									/>
								) : (
									<BlurhashCanvas
										hash={feature.hash}
										punch={1}
										className="blurhash"
									/>
								)}
							</Card>
						</Grid>
					))}
				</Grid>
			</Container>
		</>
	);
};
