import { useCallback } from "react";
import useQualifierContext from "./useQualifierContext";
import { getLevelPoolId } from "../../../api/helpers/getLevelPoolId";
import { fetchNextLevel } from "../../../api/helpers/fetchNextLevel";
import { fetchProductsById } from "../../../api/helpers/fetchProductsById";

export const useFetchLevelAndProducts = () => {
	const { dispatch } = useQualifierContext();
	const fetchNextLevelAndProducts = useCallback(
		async (id: number, level: number, isDirectUrl: boolean) => {
			dispatch({ type: "RESET_PRODUCTS" });
			dispatch({ type: "SET_DIRECT_URL", payload: false });
			dispatch({
				type: "SELECT_QUALIFIER_VALUE",
				payload: {
					id,
					level,
					pushToQualifiedUrl: true,
				},
			});
			const levelPoolId = await getLevelPoolId(id, true);
			fetchNextLevel(id, dispatch, isDirectUrl, false);
			fetchProductsById(levelPoolId, dispatch);
		},
		[]
	);

	return { fetchNextLevelAndProducts };
};
