import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./app/App";

import "./index.css";
import QualifierProvider from "./context/qualifiers/QualifierProvider";
import WizardProvider from "./context/wizard/WizardProvider";

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<QualifierProvider>
				<WizardProvider>
					<App />
				</WizardProvider>
			</QualifierProvider>
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById("root")
);
