export const subRoutes: string[] = [
	// TOOT N TOTEM
	"tnt/rm",
	"tnt/rm/",
	// QUIKTRIP
	"qt",
	"qt/",
	"qt/rsn",
	"qt/rsn/",
	"qt/rm",
	"qt/rm/",
	"qt/aussa",
	"qt/aussa/",
	"qt/dfw",
	"qt/dfw/",
	"qt/az",
	"qt/az/",
	"qt/uat",
	"qt/uat/",
	"qt/atl",
	"qt/atl/",
	"qt/dmo",
	"qt/dmo/",
	"qt/stl",
	"qt/stl/",
	"qt/kc",
	"qt/kc/",
	"qt/distribution",
	"qt/distribution/",
	"qt/houston",
	"qt/houston/",
	"qt/chi",
	"qt/chi/",
	"qt.html",
	// ROYAL FARMS
	"rf",
	"rf/",
	"rf/fpm",
	"rf/fpm/",
	"rf/md",
	"rf/md/",
	"rf/md/alc",
	"rf/md/alc/",
	"rf/pa",
	"rf/pa/",
	"rf/wv",
	"rf/wv/",
	"rf/ust",
	"rf/ust/",
	"rf/va",
	"rf/va/",
	"rf/va/chesapeake",
	"rf/va/chesapeake/",
	"rf/va/norfolk",
	"rf/va/norfolk/",
	"rf/va/portsmouth",
	"rf/va/portsmouth/",
	"rf/va/smithfield",
	"rf/va/smithfield/",
	"rf/va/other",
	"rf/va/other/",
	"rf/fsm.html",
	"rf/maryland.html",
	"rf/pennsylvania.html",
	"rf/virginia.html",
	// SHEETZ
	"sheetz",
	// STARCON
	"starcon",
	"starcon/",
	"starcon/hourlyemployees",
	"starcon/hourlyemployees/",
	"starcon/hmsmanagers",
	"starcon/hmsmanagers/",
	"starcon/managers",
	"starcon/managers/",
	// NEXT LEVEL MARKETING
	"nlm",
	"nlm/",
];

export const legacySubserviceRoutes: string[] = [
	"qt/*",
	"rf/*",
	"sheetz/*",
	"starcon/*",
	"nlm/*",
];
