import { Box, Theme, Typography, useMediaQuery, useTheme } from "@mui/material";
import { FC, useEffect, useState } from "react";
import parse from "html-react-parser";
import { BlurhashCanvas } from "react-blurhash";

export const Banner: FC<{ headingText?: string[] }> = ({ headingText }) => {
	const headerImg = headingText ? "new_header" : "newheader_compressed";
	const theme: Theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("md"));

	const imgSrc = `${process.env.REACT_APP_S3_IMAGE_BASE_URL}/${headerImg}.webp`;

	const [imageLoaded, setImageLoaded] = useState(false);

	useEffect(() => {
		const img = new Image();
		img.onload = () => {
			setImageLoaded(true);
		};
		img.src = imgSrc;
	}, []);

	return (
		<>
			<Box sx={{ position: "relative" }}>
				<div className="hero-banner">
					{imageLoaded ? (
						<img
							role="presentation"
							decoding="async"
							src={imgSrc}
							alt="Get Food &amp; Bev Certified Today!"
							className="banner-img"
						/>
					) : (
						<BlurhashCanvas
							hash="LCQ,8qR*_Nx]^kRjE1t7tRV@DiWC"
							width={900}
							height={300}
							punch={1}
							className="blurhash"
						/>
					)}
					<div
						style={{
							position: "absolute",
							top: "55%",
							left: "50%",
							transform: "translate(-50%, -50%)",
							textAlign: "center",
							width: "100%",
						}}
					>
						{headingText &&
							// eslint-disable-next-line array-callback-return
							headingText.map((t) => {
								if (t.includes("h2"))
									return (
										<Typography
											fontSize={
												isMobile ? "small" : "large"
											}
										>
											{parse(t)}
										</Typography>
									);
								if (t.includes("h4"))
									return (
										<Typography
											fontSize={
												isMobile ? "small" : "medium"
											}
										>
											{parse(t)}
										</Typography>
									);
							})}
					</div>
				</div>
			</Box>
		</>
	);
};
