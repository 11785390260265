import { useState, MouseEvent } from "react";
import { Link } from "react-router-dom";
import { Box, Typography, Menu, MenuItem, Tooltip } from "@mui/material";
import { MenuProps } from "../types/MenuProps";
import { useInvokeWizard } from "../../../context/wizard/hooks/useInvokeWizard";

export const DesktopMenu = ({
	navItems,
	productLines,
	getQualifiersByParentId,
}: MenuProps) => {
	const { invokeWizard } = useInvokeWizard();

	const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
	const handleOpenNavMenu = (event: MouseEvent<HTMLElement>) => {
		setAnchorElNav(event.currentTarget);
	};
	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	const setProductLineAndCloseNavMenu = async (id: number, level: number) => {
		getQualifiersByParentId(id, level);
		handleCloseNavMenu();
	};

	return (
		<>
			<Box
				sx={{
					display: { xs: "none", md: "flex" },
				}}
			>
				<Menu
					sx={{ mt: "45px" }}
					id="menu-appbar"
					anchorEl={anchorElNav}
					anchorOrigin={{
						vertical: "top",
						horizontal: "right",
					}}
					keepMounted
					transformOrigin={{
						vertical: "top",
						horizontal: "right",
					}}
					open={Boolean(anchorElNav)}
					onClose={handleCloseNavMenu}
				>
					{productLines &&
						productLines.values.map((pl, index) => (
							<Link
								to={`/products/${pl.urlFragment}`}
								key={index}
								onClick={() =>
									setProductLineAndCloseNavMenu(
										pl.id,
										productLines.level
									)
								}
							>
								<MenuItem>{pl.value}</MenuItem>
							</Link>
						))}
				</Menu>
				{navItems.map((page, index) => (
					<Typography textAlign="center" key={index}>
						{page.linkTo && (
							<Link to={page.linkTo}>
								<MenuItem>{page.name}</MenuItem>
							</Link>
						)}
						{page.url && !page.class && (
							<a href={page.url}>
								<MenuItem>{page.name}</MenuItem>
							</a>
						)}
						{page.class && (
							<button
								className="ess-help"
								onClick={() =>
									invokeWizard(
										`${process.env.REACT_APP_TXN_URL}/helps/catalog/mst`
									)
								}
								data-testid="faq"
							>
								<MenuItem>{page.name}</MenuItem>
							</button>
						)}
						{page.isDropdown && (
							<Tooltip title="Select product line">
								<MenuItem onClick={handleOpenNavMenu}>
									{page.name}
								</MenuItem>
							</Tooltip>
						)}
					</Typography>
				))}
			</Box>
		</>
	);
};
