// PHASE 2:
// TODO: ADD LINK TO THE MAP
import { MouseEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	Divider,
	Grid,
	ListItem,
	Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { primaryColor } from "../../app/theme";
import { ProductLineCards } from "../../components/ProductLineCards";
import useQualifierContext from "../../context/qualifiers/hooks/useQualifierContext";
import { Qualifier } from "../../context/qualifiers/types/StateType";
import { Banner } from "../../components/Banner";
import { Testimonials } from "../../components/Testimonials";
import { useFetchLevelAndProducts } from "../../context/qualifiers/hooks/useFetchLevelAndProducts";

export const Employers = () => {
	const navigate = useNavigate();

	const {
		state: { qualifiers },
	} = useQualifierContext();

	const { fetchNextLevelAndProducts } = useFetchLevelAndProducts();

	// GET LEVEL 2 QUALIFIERS (PRODUCT LINES)
	const productLines: Qualifier = qualifiers.filter(
		(q: Qualifier) => q.level === 2
	)[0];

	const [isExpanded, setIsExpanded] = useState<boolean>(false);

	const toggleAccordian = () => setIsExpanded(!isExpanded);

	const selectProductLine = async (event: MouseEvent) => {
		const { currentTarget } = event;
		const { id, level, url } = (currentTarget as HTMLDivElement).dataset;

		fetchNextLevelAndProducts(Number(id), Number(level), false);

		navigate(`/products/${url}`);
	};

	const colorRed = { color: primaryColor };

	const headingText = [
		`<h2 style="font-weight: bold; margin: 0;">Need to get your team trained?</h2>`,
		`<h4 style="color: ${primaryColor}; margin: 0;">Bulk Pricing, No Contracts, No Hassle. Let&#39;s Talk.</h4>`,
	];

	return (
		<div>
			<Banner headingText={headingText} />

			<Box
				sx={{
					margin: "1.6rem auto",
					maxWidth: "80%",
				}}
			>
				<Typography fontSize="large" textAlign="center">
					We cater to enterprises and their training, compliance, and
					risk management needs. We work hard to make your entire
					training and compliance experience as easy, efficient, and
					painless as it possibly can be. We understand the challenges
					our clients face managing their businesses and their team
					members on a day-to-day basis and that drives a constant
					focus on improving the entire process, from purchase and
					enrollment, to the quality of our courses and learning
					platform, to the tracking of your team members training, and
					providing the best possible support along the way.
				</Typography>
			</Box>

			<Testimonials />

			<Box sx={{ margin: "1.6rem auto" }}>
				<Typography variant="h5" textAlign="center">
					Purchase
					<span style={colorRed}> Training</span> Vouchers
				</Typography>

				<Grid
					container
					direction="row"
					columns={14}
					rowSpacing={1}
					columnSpacing={0}
					maxWidth="lg"
				>
					<Grid item lg={6} md={6} style={{ padding: "20px" }}>
						<Typography
							fontSize="medium"
							style={{ marginBottom: "15px" }}
						>
							<b>Choose MyFoodandBevTraining.com</b> as your
							preferred training provider and you and your team
							members will enjoy:
						</Typography>
						<Typography
							fontSize="medium"
							style={{ marginBottom: "15px" }}
						>
							<b>Convenience</b> Purchase vouchers in bulk &amp;
							distribute to your employees for accelerated course
							enrollment.
						</Typography>
						<Typography
							fontSize="medium"
							style={{ marginBottom: "15px" }}
						>
							<b>Mobile Friendly</b> Courses can be taken on Smart
							Phones, Tablets, or PCs.
						</Typography>
						<Typography
							fontSize="medium"
							style={{ marginBottom: "15px" }}
						>
							<b>Instant Access</b> Immediate access to course
							completion certificates to support liquor control or
							health department inspections and audits.
						</Typography>
						<Typography
							fontSize="medium"
							style={{ marginBottom: "15px" }}
						>
							<b>Live and Email Help</b> Live, dedicated helpdesk
							support for you and your employees.
						</Typography>
						<Typography fontSize="medium">
							<b>History Reporting</b> Instantly access course
							status and voucher usage for all your team members.
						</Typography>
					</Grid>

					<Grid
						item
						lg={6}
						md={6}
						sm={4}
						style={{
							padding: 0,
							border: `3px solid ${primaryColor}`,
							borderRadius: "10px",
						}}
					>
						<Typography variant="h5" textAlign="center">
							EASY as 1-2-3
						</Typography>
						<div style={{ padding: "0 10px" }}>
							<p className="lead">
								<ListItem>
									<Typography fontSize="medium">
										<span
											style={{
												...colorRed,
												fontWeight: "bold",
											}}
										>
											1.
										</span>{" "}
										Purchase the number of Training Vouchers
										you need and instantly receive your
										unique Voucher Code (which will not
										expire until the quantity purchased has
										been used.)
									</Typography>
								</ListItem>
								<ListItem>
									<Typography fontSize="medium">
										<span
											style={{
												...colorRed,
												fontWeight: "bold",
											}}
										>
											2.
										</span>{" "}
										Distribute the code to your team members
										to use as payment when they register for
										a course.
									</Typography>
								</ListItem>
								<ListItem>
									<Typography fontSize="medium">
										<span
											style={{
												...colorRed,
												fontWeight: "bold",
											}}
										>
											3.
										</span>{" "}
										Track who has registered, who is in
										progress, who has completed, and how
										many times the code has been used, at
										any time you want using our free Voucher
										History Tool.
									</Typography>
								</ListItem>
							</p>

							<Accordion
								expanded={isExpanded}
								onClick={toggleAccordian}
							>
								<Button
									variant="contained"
									sx={{
										width: "95%",
										marginLeft: "2.5%",
										boxShadow: "0 5px 10px rgba(0,0,0,.5)",
									}}
								>
									<AccordionSummary
										expandIcon={
											<ExpandMoreIcon
												sx={{ color: "white" }}
											/>
										}
										aria-controls="panel-content"
										id="panel-header"
									>
										Select Product Line
									</AccordionSummary>
								</Button>
								<AccordionDetails>
									<ProductLineCards
										productLines={productLines}
										selectProductLine={selectProductLine}
										mediumValue={15}
										largeValue={15}
									/>
								</AccordionDetails>
							</Accordion>
						</div>
					</Grid>
				</Grid>
			</Box>

			<Divider />

			<Typography variant="h5" textAlign="center">
				<span style={colorRed}>Corporate</span> Accounts
			</Typography>

			<Grid
				container
				columns={14}
				direction="row"
				rowSpacing={1}
				columnSpacing={1}
				maxWidth="lg"
			>
				<Grid item lg={6} style={{ padding: "20px" }}>
					<Typography
						fontSize="medium"
						style={{ marginBottom: "30px" }}
					>
						<b>Corporate accounts</b>&nbsp; also have access to more
						valuable, free services. Fill out the form below and
						we&#39;ll call you to discuss these options.
					</Typography>
					<Typography
						fontSize="medium"
						style={{ marginBottom: "30px" }}
					>
						<b>Dedicated Account Manager</b>&nbsp; Ongoing,
						dedicated, U.S.-based account manager to support you.
					</Typography>
					<Typography
						fontSize="medium"
						style={{ marginBottom: "30px" }}
					>
						<b>Enrollment and Billing Options</b>&nbsp;A variety of
						options can be implemented to support the employee
						experience and accounting processes of your business.
					</Typography>
					<Typography
						fontSize="medium"
						style={{ marginBottom: "30px" }}
					>
						<b>Compliance Reminders</b>&nbsp; Emails to you and/or
						your team members to remind you of training renewal
						dates based on your regulatory jurisdiction&#39;s rules.
					</Typography>
					<Typography
						fontSize="medium"
						style={{ marginBottom: "30px" }}
					>
						<b>Store ID Selection </b>&nbsp; Provide us your store
						list. Your team members will select their Store ID
						during registration.
					</Typography>
				</Grid>
				<Grid item lg={6} style={{ padding: "20px" }}>
					<Typography
						fontSize="medium"
						style={{ marginBottom: "30px" }}
					>
						<b>Accounting Reports by Store ID</b>&nbsp; Using Store
						ID numbers captured during registration we&#39;ll
						provide the detail your accounting department needs to
						allocate training costs by store, division, region or
						however you work.
					</Typography>
					<Typography
						fontSize="medium"
						style={{ marginBottom: "30px" }}
					>
						<b>Risk Management Reporting Dashboard</b>
						&nbsp; Instant access for your management team users
						including completion and expiration dates,
						region/division/store IDs, name, course, result, score
						and instant certificate access.
					</Typography>
					<Typography
						fontSize="medium"
						style={{ marginBottom: "30px" }}
					>
						<b>Private-labelled Registration Portal</b>
						&nbsp; Branded to match your website styling to provide
						a private URL for employee course registration and log
						in. Optional integration with your website allows your
						employees to initiate their training from your own site.
					</Typography>
					<Typography
						fontSize="medium"
						style={{ marginBottom: "30px" }}
					>
						<b>Regulatory Expertise &amp; Research</b>
						&nbsp; Assistance in understanding the regulatory
						requirements in any jurisdiction you operate within.
					</Typography>
				</Grid>
			</Grid>

			<Divider />
		</div>
	);
};
