import { FC } from "react";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import { primaryColor } from "../../app/theme";
import { ProductLineCardsProps } from "./types/ProductLineCardsProps";
import { QualifierValue } from "../../context/qualifiers/types/StateType";

export const ProductLineCards: FC<ProductLineCardsProps> = ({
	productLines,
	selectProductLine,
	mediumValue,
	largeValue,
}) => {
	return (
		<>
			{productLines &&
				productLines.values.map(
					(plValue: QualifierValue, index: number) => (
						<Grid
							item
							xs={15}
							sm={15}
							md={mediumValue}
							lg={largeValue}
							key={index}
							m={0}
							p={0}
							sx={{ justifyContent: "center" }}
						>
							<Card
								sx={{
									cursor: "pointer",
									boxShadow: "0 5px 10px rgba(0,0,0,.3)",
									transition: "all .2s ease",
									marginBottom: "1rem",
									border: "2px solid transparent",
									"&:hover": {
										border: `2px solid ${primaryColor}`,
										boxShadow: "0 8px 12px rgba(0,0,0,.2)",
									},
								}}
							>
								<CardContent
									data-testid="productLineCard"
									data-id={plValue.id}
									data-level={plValue.level}
									data-url={plValue.urlFragment}
									onClick={selectProductLine}
								>
									<div
										style={{
											display: "flex",
											flexFlow: "row wrap",
											justifyContent: "space-around",
											alignItems: "center",
										}}
									>
										<div style={{ textAlign: "center" }}>
											<Typography
												fontSize="medium"
												fontWeight="bold"
											>
												<span
													style={{
														display: "inline-block",
														width: "26px",
														position: "relative",
														top: 10,
													}}
												>
													{plValue.decorators &&
														plValue
															.decorators[0] && (
															<img
																src={`${plValue.decorators[0].value}`}
																alt={`${plValue.decorators[0].key}`}
																width="100%"
																height="auto"
															/>
														)}
												</span>
												&nbsp;
												{plValue.value}
											</Typography>
										</div>
									</div>
								</CardContent>
							</Card>
						</Grid>
					)
				)}
		</>
	);
};
