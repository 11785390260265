import {
	useState,
	MouseEvent,
	useContext,
	useEffect,
	useCallback,
} from "react";
import { Link } from "react-router-dom";
import { Box, Typography, Menu, MenuItem } from "@mui/material";
import { SubserviceMenuProps } from "../types/MenuProps";
import parse from "html-react-parser";
import useQualifierContext from "../../../../../context/qualifiers/hooks/useQualifierContext";
import { NavDropdownItemsType } from "../types/NavItems";
import { useInvokeWizard } from "../../../../../context/wizard/hooks/useInvokeWizard";

export const DesktopMenu = ({
	navItems,
	qualifier,
	getQualifiersByParentId,
	navItemColor,
}: SubserviceMenuProps) => {
	const {
		state: { service },
	} = useQualifierContext();

	const { invokeWizard } = useInvokeWizard();

	const [openItems, setOpenItems] = useState<{ [key: number]: boolean }>({});
	const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
	const [dropDownItems, setDropDownItems] = useState<
		undefined | NavDropdownItemsType[]
	>(undefined);

	const handleOpenNavMenu = (
		event: MouseEvent<HTMLElement>,
		index: number
	) => {
		const dropDownMenuItem = navItems.find(
			(item) => item.class === event.currentTarget.className
		);
		setDropDownItems(dropDownMenuItem?.dropdownItems);
		setOpenItems({ [index]: true });
		setAnchorElNav(event.currentTarget);
	};
	const handleCloseNavMenu = () => {
		setOpenItems({});
		setAnchorElNav(null);
	};

	const setProductLineAndCloseNavMenu = async (id: number, level: number) => {
		getQualifiersByParentId(id, level);
		handleCloseNavMenu();
	};

	useEffect(() => {}, [dropDownItems]);

	return (
		<>
			<Box
				sx={{
					display: { xs: "none", md: "flex" },
				}}
			>
				<Menu
					sx={{ mt: "45px" }}
					id="menu-appbar"
					anchorEl={anchorElNav}
					anchorOrigin={{
						vertical: "top",
						horizontal: "right",
					}}
					keepMounted
					transformOrigin={{
						vertical: "top",
						horizontal: "right",
					}}
					open={Boolean(anchorElNav)}
					onClose={handleCloseNavMenu}
				>
					{qualifier &&
						!dropDownItems &&
						qualifier.values.map((q, index) => (
							<Link
								to={`${service.name}/${q.urlFragment}`}
								key={index}
								onClick={() =>
									setProductLineAndCloseNavMenu(
										q.id,
										qualifier.level
									)
								}
							>
								<MenuItem>{q.value}</MenuItem>
							</Link>
						))}
					{dropDownItems &&
						dropDownItems.map((item, index) => (
							<MenuItem key={index}>
								{item.linkTo && (
									<Link
										className="nav-dropdown-item"
										to={item.linkTo}
									>
										{item.name}
									</Link>
								)}
								{item.useWizard && (
									<button
										className="nav-dropdown-item"
										onClick={() =>
											invokeWizard(item.url ?? "/")
										}
										data-testid="faq"
									>
										{item.name}
									</button>
								)}
								{item.url && !item.useWizard && (
									<a
										className="nav-dropdown-item"
										href={item.url}
										target="_blank"
										rel="noreferrer"
									>
										{item.name}
									</a>
								)}
							</MenuItem>
						))}
				</Menu>
				{navItems.map((navItem, index) => (
					<Typography
						className="subservice-nav-item"
						textAlign="center"
						key={index}
						sx={{ color: navItemColor }}
					>
						{navItem.linkTo && (
							<Link
								className="subservice-nav-item"
								to={navItem.linkTo}
							>
								<MenuItem>{navItem.name}</MenuItem>
							</Link>
						)}
						{navItem.url && !navItem.class && (
							<a
								className="subservice-nav-item"
								href={navItem.url}
							>
								<MenuItem>{navItem.name}</MenuItem>
							</a>
						)}
						{navItem.class && !navItem.isDropdown && (
							<button
								className={navItem.class}
								onClick={() =>
									invokeWizard(
										`${process.env.REACT_APP_TXN_URL}/helps/catalog/mst`
									)
								}
								data-testid="faq"
							>
								<MenuItem>{navItem.name}</MenuItem>
							</button>
						)}
						{navItem.isDropdown && (
							<button
								className={
									navItem.class ?? "subservice-nav-item"
								}
								onClick={(event) =>
									handleOpenNavMenu(event, index)
								}
							>
								<MenuItem>
									{navItem.name}{" "}
									{openItems[index]
										? parse("&#8593;")
										: parse("&#8595;")}
								</MenuItem>
							</button>
						)}
					</Typography>
				))}
			</Box>
		</>
	);
};
