import { Dispatch } from "react";
import { QualifierActionType } from "../../context/qualifiers/types/QualifierProviderType";
import { Qualifier } from "../../context/qualifiers/types/StateType";
import qualifierBindingApi from "../qualifierBinding.api";
import qualifierLevelApi from "../qualifierLevel.api";
import { fetchNextLevel } from "./fetchNextLevel";
import findService from "../../context/helpers/findService";

export type Ancestor = { id: string };

export const fetchProductsByUrl = async (
	url: string,
	serviceId: number,
	dispatch: Dispatch<QualifierActionType>
) => {
	let products = null;
	try {
		await qualifierBindingApi.getBindingEntriesByUrl(url).then((res) => {
			if (res.exception) {
				throw new Error(`${res.exception}: ${res.msg}`);
			}
			if (res.items.length > 0) {
				dispatch({
					type: "STORE_PRODUCTS",
					payload: res,
				});
			}

			const getAllLevels = async () => {
				const ancestors: Ancestor[] = res.items[0].ancestors;
				if (ancestors.length > 0) {
					const trimmedAncestors: Ancestor[] = ancestors.slice(0, -1);

					const promises = trimmedAncestors.map((a) => {
						return qualifierLevelApi.getQualifiersByParentId(
							Number(a.id),
							true
						);
					});

					Promise.all(promises)
						.then((values) => {
							dispatch({
								type: "SET_DIRECT_URL",
								payload: true,
							});
							values.forEach((item: Qualifier) => {
								dispatch({
									type: "ADD_NEXT_QUALIFIER_LEVEL",
									payload: item,
								});
							});
						})
						.then(() => {
							ancestors.slice(1).forEach((a, index) => {
								dispatch({
									type: "SET_SELECTED_QUALIFIER",
									payload: {
										id: Number(a.id),
										level: index + 2,
										pushToQualifiedUrl: true,
									},
								});
							});
						});
				}
			};
			getAllLevels();

			products = res.items;
		});
	} catch (error: any) {
		let message = "Unknown Error";
		const status = error.response.status;
		const url = error.config.url;
		const baseURL = error.config.baseURL;

		if (baseURL.includes("esslearning.com") && status === 404) {
			const fetchProductLineData = async () => {
				const currentService = findService(serviceId);

				let productLines: Qualifier =
					await qualifierLevelApi.getQualifiersByParentId(
						currentService!.parentId,
						false
					);

				if (!productLines) {
					window.location.replace("/not-found");
				}
				const selectedProductLine = productLines?.values.find((val) =>
					url.includes(val.urlFragment)
				);
				await fetchNextLevel(
					currentService!.parentId,
					dispatch,
					false,
					true
				);

				await fetchNextLevel(
					selectedProductLine?.id ?? 0,
					dispatch,
					true,
					false
				);

				dispatch({ type: "POP_QUALIFIED_URL" });

				dispatch({
					type: "SET_SELECTED_QUALIFIER",
					payload: {
						id: selectedProductLine?.id ?? 0,
						level: 2,
						pushToQualifiedUrl: false,
					},
				});
			};
			fetchProductLineData();
		} else {
			if (error instanceof Error) {
				message = error.message;
			}
			console.error(message)
			window.location.replace("/not-found");
		}
	}

	return products;
};
