import { FC, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { SetSubserviceProps } from "./types/SetSubserviceProps";

export const Subservice: FC<SetSubserviceProps> = ({ setSubserviceLoaded }) => {
	const s3host = process.env.REACT_APP_S3_BASE_URL;
	const { pathname, hash } = useLocation();

	const sanitizationMap: Record<string, string> = {
		"/qt.html": "/qt/index.html",
		"/rf/fsm.html": "/rf/fpm/index.html",
		"/rf/maryland.html": "/rf/md/index.html",
		"/rf/pennsylvania.html": "/rf/pa/index.html",
		"/rf/virginia.html": "/rf/va/index.html",
	};

	const checkVeryCornerCaseScenario = (pathname: string) => {
		if (pathname.includes("tnt")) return "/tnt/rm/index.html";
		if (!pathname.includes(".html")) {
			return pathname.endsWith("/")
				? `${pathname}index.html`
				: `${pathname}/index.html`;
		}
		return sanitizationMap[pathname] ?? pathname;
	};

	const sanitizedPath = checkVeryCornerCaseScenario(
		pathname.toLocaleLowerCase()
	);

	const src = `${s3host}${sanitizedPath}?iframe=true${hash}`;

	useEffect(() => {
		setSubserviceLoaded(1);
	}, [setSubserviceLoaded]);

	return (
		<div>
			<iframe
				title="subserviceFrame"
				src={src}
				style={{
					position: "absolute",
					top: "0",
					left: "0",
					bottom: "0",
					right: "0",
					width: "100vw",
					height: "100vh",
					border: "none",
					padding: "0",
					margin: "0",
					zIndex: "99999",
				}}
			></iframe>
		</div>
	);
};
