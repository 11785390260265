import qualy from "./axios";
import { findLegacySubservice } from "./helpers/findLegacySubservice";
import { sortQualifierValues } from "./helpers/sortQualifierValues";

const isLegacySubservice = findLegacySubservice();

const qualifierLevelApi = {
	getQualifiersByLevel: async (
		level: number,
		getDecorators: boolean,
		tableName?: string,
		rowId?: number
	) => {
		if (isLegacySubservice) return;
		let params = { level, getDecorators };
		if (getDecorators) params = Object.assign(params, { tableName, rowId });
		return qualy
			.get(`/qualifierLevel`, {
				params,
			})
			.then((res) => {
				sortQualifierValues(res.data.values);
				return res.data;
			});
	},
	getQualifiersByParentId: async (
		parentId: number,
		getDecorators: boolean,
		tableName?: string,
		rowId?: number
	) => {
		if (isLegacySubservice) return;
		let params = { parentId, getDecorators };
		if (getDecorators) params = Object.assign(params, { tableName, rowId });
		return qualy
			.get(`/qualifierLevelPool`, {
				params: { parentId, getDecorators },
			})
			.then((res) => {
				sortQualifierValues(res.data.values);
				return res.data;
			});
	},
	getRawQualifiersByParentId: async (
		parentId: number,
		getDecorators: boolean,
		tableName?: string,
		rowId?: number
	) => {
		if (isLegacySubservice) return;
		let params = { parentId, getDecorators };
		if (getDecorators) params = Object.assign(params, { tableName, rowId });
		return qualy
			.get(`/qualifierLevelPool`, {
				params: { parentId, getDecorators },
			})
			.then((res) => {
				return res.data;
			});
	},
};

export default qualifierLevelApi;
