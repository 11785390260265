import { Grid } from "@mui/material";
import { SyntheticEvent, useState } from "react";
import useQualifierContext from "../../../../context/qualifiers/hooks/useQualifierContext";
import { QualifierBindingEntry } from "../../../../context/qualifiers/types/StateType";
import { ProductCard } from "./ProductCard";
import { useInvokeWizard } from "../../../../context/wizard/hooks/useInvokeWizard";

export const Products = ({
	primaryColor,
	secondaryColor,
	accentColor,
}: {
	primaryColor: string;
	secondaryColor: string;
	accentColor?: string;
}) => {
	const [expanded, setExpanded] = useState<string | false>(false);

	const {
		state: { products },
	} = useQualifierContext();

	const { invokeWizard } = useInvokeWizard();

	const handleChange =
		(panel: string) => (event: SyntheticEvent, newExpanded: boolean) => {
			setExpanded(newExpanded ? panel : false);
		};

	return (
		<>
			<Grid
				container
				columns={1}
				direction="column"
				rowSpacing={4}
				columnSpacing={4}
				sx={{
					justifyContent: "space-around",
					alignItems: "center",
					textAlign: "center",
					fontSize: "2rem",
					marginBottom: "3rem",
				}}
			>
				{products &&
					products.items.map(
						(p: QualifierBindingEntry, pIndex: number) => {
							if (p.enabled && p.isActive)
								return (
									<ProductCard
										key={`${p.id}-${pIndex}`}
										product={p}
										primaryColor={primaryColor}
										secondaryColor={secondaryColor}
										accentColor={accentColor}
										expanded={expanded}
										handleChange={handleChange}
										handleWizardButtonClick={invokeWizard}
									/>
								);
							else return <></>;
						}
					)}
			</Grid>
		</>
	);
};
