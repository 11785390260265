import { FC } from "react";
import { Container, Divider, Grid, Typography } from "@mui/material";
import { Features } from "../../components/Features";
import { primaryColor } from "../../app/theme";
import { Link } from "react-router-dom";

export const About: FC = () => {
	const colorRed = { color: primaryColor };
	return (
		<Container maxWidth={"xl"}>
			<Grid
				container
				columns={12}
				columnSpacing={2}
				width="100%"
				padding="1rem"
			>
				<Grid item lg={9} md={9} sm={12} xs={12}>
					<Typography variant="h5" textAlign="center">
						ABOUT MY <span style={colorRed}>FOOD</span> &amp;{" "}
						<span style={colorRed}>BEV </span>
						TRAINING
					</Typography>
					<Typography
						fontSize="1rem"
						marginBottom="1rem"
						fontWeight="bold"
					>
						<Link to="/" style={colorRed}>
							MyFoodandBevTraining.com
						</Link>{" "}
						is operated by My State Training, LLC, a subsidiary of
						eStrategy Solutions, Inc. We offer you the best online
						courses and the simplest experience for both buyers and
						students available anywhere in the U.S., and while we
						offer complete customer support, less than one half of
						one percent of our users ever have the need to contact
						us.
					</Typography>
					<Typography
						fontSize="1rem"
						marginBottom="1rem"
						fontWeight="light"
					>
						eStrategy Solutions, Inc. (eSS) is an e-learning
						services provider, with eighteen years of experience
						leading and implementing &#34;pain-free&#34; SaaS-based
						solutions for online training and testing for state
						licensing agencies, boards and affiliates. Our state
						agency clients have for many years consistently rated
						eSS number one in both pricing and customer service.
					</Typography>
					<Typography fontSize="1rem" fontWeight="light">
						If you&#39;d like to speak to Sales, please fill out the
						form at the bottom of the page and we will reach out to
						you to discuss your needs and how we can help.
					</Typography>
				</Grid>
			</Grid>

			<Divider />

			<Features />
		</Container>
	);
};
