import { FC } from "react";
import parse from "html-react-parser";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Button,
	Card,
	Grid,
	Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { productMetadataMapping } from "../../../../Products/ProductCards/productMetadataMapping";
import { ProductCardProps } from "../types/ProductCardProps";

export const ProductCard: FC<ProductCardProps> = ({
	product,
	primaryColor,
	secondaryColor,
	accentColor,
	expanded,
	handleChange,
	handleWizardButtonClick,
}) => {
	const metadata = product.product;

	const tabEntries = Object.entries(metadata) as [string, string | number][];
	const tabs = tabEntries.filter((entry) => {
		return (
			(entry[0] === "notice" && entry[1]) ||
			(entry[0] === "credits" && entry[1]) ||
			(entry[0] === "includes" && entry[1])
		);
	});

	const wizardUrl = `${process.env.REACT_APP_TXN_URL}/wizard/${metadata.catalog.merchant.apikey}/${metadata.apikey}`;

	return (
		product && (
			<Grid item key={product.id} sx={{ width: "100%" }}>
				<Card
					sx={{
						border: "1px solid #ddd",
						borderRadius: "10px",
						boxShadow: "0 5px 10px rgba(0,0,0,.5)",
						padding: 0,
					}}
				>
					<Grid
						container
						columns={5}
						direction="row"
						spacing={0}
						p={2}
					>
						{/* product details */}
						<Grid item xl={3.6} lg={3} md={3} sm={5}>
							<Grid
								container
								direction="column"
								alignItems="flex-start"
								py={2}
								px={2}
							>
								<Typography
									variant="h5"
									textTransform="none"
									mb={2}
									display="block"
								>
									{metadata.title}
								</Typography>
								<Typography textAlign="left" fontSize={18}>
									{parse(metadata.synopsis)}
								</Typography>
							</Grid>
						</Grid>
						{/* wizard buttons */}
						<Grid item xl={1.4} lg={2} md={2} sm={5}>
							<Grid container padding={0} direction="column">
								<Button
									variant="contained"
									data-testid="productCard"
									onClick={() =>
										handleWizardButtonClick(
											`${wizardUrl}/product-purchase`
										)
									}
									sx={{
										backgroundColor: primaryColor,
										margin: "5px",
										"&:hover": {
											backgroundColor:
												accentColor || secondaryColor,
										},
									}}
								>
									Enroll Now &nbsp;
									{metadata.price > 0 && (
										<b>${metadata.price.toFixed(2)}</b>
									)}
								</Button>
								{metadata.bulk && metadata.voucherable && (
									<>
										<Button
											variant="outlined"
											data-testid="productCard"
											onClick={() =>
												handleWizardButtonClick(
													`${wizardUrl}/voucher-purchase`
												)
											}
											sx={{
												border: `1px solid ${primaryColor}`,
												color: primaryColor,
												margin: "5px",
												"&:hover": {
													border: `1px solid ${secondaryColor}`,
													color: secondaryColor,
												},
											}}
										>
											Bulk Purchase
										</Button>
										<Button
											variant="outlined"
											data-testid="productCard"
											onClick={() =>
												handleWizardButtonClick(
													`${wizardUrl}/voucher-redemption`
												)
											}
											sx={{
												border: `1px solid ${primaryColor}`,
												color: primaryColor,
												margin: "5px",
												"&:hover": {
													border: `1px solid ${secondaryColor}`,
													color: secondaryColor,
												},
											}}
										>
											Redeem Voucher
										</Button>
									</>
								)}
							</Grid>
						</Grid>
					</Grid>

					{tabs.length > 0 &&
						tabs.map((tab, index) => (
							<Accordion
								key={`product-${product.id}-${String(tab[0])}`}
								square={false}
								expanded={
									expanded ===
									`product-${product.id}-${String(tab[0])}`
								}
								onChange={handleChange(
									`product-${product.id}-${String(tab[0])}`
								)}
							>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls="panel1d-content"
									id="panel1d-header"
								>
									<Typography
										variant="body2"
										color={primaryColor}
										px={2}
									>
										{(
											productMetadataMapping as Record<
												string,
												string
											>
										)[tab[0]] ?? tab[1]}
									</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Typography
										variant="body1"
										textAlign="left"
										color="black"
										fontSize={16}
									>
										{parse(String(tab[1]))}
									</Typography>
								</AccordionDetails>
							</Accordion>
						))}
				</Card>
			</Grid>
		)
	);
};
