import {
	Box,
	Grid,
	Theme,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import { Footer } from "../../components/Footer";
import { navItems } from "../navItems";
import { LegacyNavbar } from "../../components/LegacyNavbar";
import ProductsDescriptions from "../ProductsDescriptions/ProductsDescriptions";
import Products from "../Products/Products";
const fontFamily = "Roboto, Helvetica Neue, Helvetica, Arial, sans-serif";
const fontTextColor = "#686868";
const primaryColor = "#FFF";
const backgroundImage = `url(${process.env.REACT_APP_S3_IMAGE_BASE_URL}/exxpressmart/marty.jpg)`;
const emImage = `url(${process.env.REACT_APP_S3_IMAGE_BASE_URL}/exxpressmart/EM-Logo.jpg)`;

export const Home = () => {
	const theme: Theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	return (
		<>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					minHeight: "100vh",
				}}
			>
				<Grid container columns={12} sx={{ fontFamily }}>
					<Grid item lg={12} md={12} sm={12} xs={12}>
						<LegacyNavbar
							color={fontTextColor}
							logoMaxWidth="240px"
							navItems={navItems}
							backgroundColor={primaryColor}
							service="exxpressmart"
							servicePath="exxpressmart"
							logoUrl="EM-Logo.jpg"
							hamburgerColor={fontTextColor}
							filterLevel={3}
						/>
					</Grid>
					<Grid paddingTop={"20px"} container columns={12}>
						<Grid item lg={2} md={2} sm={1} xs={1} />

						<Grid
							item
							lg={2}
							md={2}
							sm={3}
							xs={3}
							sx={{
								backgroundImage,
								backgroundRepeat: "no-repeat",
								backgroundSize: "contain",
								backgroundColor: primaryColor,
								backgroundPosition: "50% 37%",
								mx: "auto",
								height: isMobile ? "auto" : 250,
							}}
						/>
						<Grid item lg={4} md={4} sm={7} xs={7}>
							<Grid
								item
								lg={12}
								md={12}
								sm={12}
								xs={12}
								sx={{
									backgroundImage: emImage,
									backgroundRepeat: "no-repeat",
									backgroundSize: "contain",
									backgroundColor: primaryColor,
									backgroundPosition: "50% 37%",
									mx: "auto",
									height: 125,
								}}
							/>
							<Grid
								item
								lg={12}
								md={12}
								sm={12}
								xs={12}
								alignItems="center"
								justifyContent="center"
								textAlign="center"
								height={isMobile ? "auto" : 125}
								display="flex"
								flexDirection="column"
							>
								<Typography
									variant="h6"
									fontWeight="bold"
									color={fontTextColor}
								>
									Enroll. Take it. Download your certificate.
								</Typography>
								<Typography variant="h6" color={fontTextColor}>
									Choose{" "}
									<span style={{ fontWeight: "bold" }}>
										English
									</span>{" "}
									or{" "}
									<span style={{ fontWeight: "bold" }}>
										Spanish
									</span>{" "}
									during enrollment.
								</Typography>
							</Grid>
						</Grid>
						<Grid item lg={4} md={4} sm={1} xs={1} />
					</Grid>

					<Grid
						item
						lg={12}
						md={12}
						sm={12}
						xs={12}
						sx={{
							backgroundColor: "#FBCA03",
							border: "4px solid #BB0301",
							mx: "auto",
							height: 40,
							top: 0,
							left: 0,
						}}
					/>
				</Grid>
				<Grid
					item
					lg={6}
					md={8}
					sm={10}
					xs={11}
					sx={{
						mx: "auto",
						my: "30px",
						backgroundColor: "rgba(255,255,255,0.9)",
						padding: "30px",
						textAlign: "center",
						justifyItems: "center",
					}}
				>
					<Typography
						variant="h5"
						fontWeight="bold"
						textTransform="uppercase"
					>
						EXXPRESS MART TRAINING COURSES
					</Typography>
					<Typography variant="h6">
						Required training for Exxpress Mart store employees.
					</Typography>
					<Typography variant="h6" fontWeight="bold">
						Enroll. Take it. Download your certificate.
					</Typography>
					<Typography variant="h6">
						Choose{" "}
						<span style={{ fontWeight: "bold" }}>English</span> or{" "}
						<span style={{ fontWeight: "bold" }}>Spanish</span>{" "}
						during registration.
					</Typography>
				</Grid>
				<Products />
				<ProductsDescriptions />
				<Box
					sx={{
						marginTop: "4rem",
						boxShadow:
							"0px 2px 4px -1px rgba(0,0,0,0.5), 0px 4px 5px 1px rgba(0,0,0,0.44), 0px 1px 10px 1px rgba(0,0,0,0.42)",
					}}
				>
					<Footer
						backgroundColor={primaryColor}
						color={fontTextColor}
					/>
				</Box>
			</Box>
		</>
	);
};
