export type ProductMetadataType = {
	synopsis: string;
	notice: string;
	credits: string;
	includes: string;
};

export const productMetadataMapping: ProductMetadataType = {
	synopsis: "Description",
	notice: "FAQs",
	credits: "How to Get Certified",
	includes: "Recommended Study",
};
