import { lazy, Suspense } from "react";
import { CircularProgress, Container, Grid } from "@mui/material";
import useQualifierContext from "../../../../context/qualifiers/hooks/useQualifierContext";
import { Qualifier } from "../../../../context/qualifiers/types/StateType";

const Dropdown = lazy(() => import("./Dropdown"));

interface FocusedQualifier extends Qualifier {
	focused: boolean;
}

export const Qualifiers = () => {
	const {
		state: { qualifiers },
		filterLevel,
	} = useQualifierContext();

	const focusedQualifiers: FocusedQualifier[] = qualifiers
		.map((q: Qualifier, index: number) => {
			return index === qualifiers.length - 1
				? { ...q, focused: true }
				: { ...q, focused: false };
		})
		.slice(filterLevel ? filterLevel - 1 : 0);

	return (
		<Container
			sx={{
				display: "flex",
				flexFlow: "row wrap",
				justifyContent: "space-around",
				alignItems: "center",
				mb: "3rem",
			}}
		>
			<Grid
				container
				columns={12}
				direction="row"
				rowSpacing={2}
				columnSpacing={1}
				maxWidth="lg"
				justifyContent="flex-start"
			>
				<Suspense fallback={<CircularProgress />}>
					{qualifiers &&
						focusedQualifiers.map(
							(q) =>
								q.level > 1 && (
									<Grid
										item
										lg={3}
										md={3}
										sm={5}
										xs={12}
										key={`${q.level}-${Date.now()}`}
										alignSelf="flex-start"
										sx={{ minHeight: "10vh" }}
									>
										<Dropdown
											name={q.name}
											level={q.level}
											values={q.values}
											focused={q.focused}
										/>
									</Grid>
								)
						)}
				</Suspense>
			</Grid>
		</Container>
	);
};
