import { FC } from "react";
import { Button, Container, Divider, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export const NotFound: FC = () => {
	return (
		<>
			<Container sx={{ margin: "5rem auto" }}>
				<Typography textAlign="center">
					We cannot find what you&#39;re looking for...
					<Link to="/">
						<Button>Go home</Button>
					</Link>
				</Typography>
			</Container>
			<Divider />
		</>
	);
};
