import { QualifierValue } from "../../context/qualifiers/types/StateType";

export const sortQualifierValues = (values: QualifierValue[]) => {
	// FOR PRODUCT LINES SORT BY ranking
	if (values[0].level === 2) {
		values.sort((a: QualifierValue, b: QualifierValue) => {
			if (a.ranking < b.ranking) return -1;
			if (a.ranking > b.ranking) return 1;
			return 0;
		});
	} else {
		// FOR EVERYTHING ELSE SORT BY isReserved AND THEN BY ALPHA
		values.sort((a: QualifierValue, b: QualifierValue) => {
			if (a.isReserved && b.isReserved) return 0;
			if (a.isReserved) return -1;
			if (b.isReserved) return 1;
			if (a.value < b.value) return -1;
			if (a.value > b.value) return 1;
			return 0;
		});
	}
};
