import { FunctionComponent, useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { fetchNextLevel } from "../../../../api/helpers/fetchNextLevel";
import { fetchProductsByUrl } from "../../../../api/helpers/fetchProductsByUrl";
import { SubserviceHomeProps } from "./types/SubserviceHomeProps";
import useQualifierContext from "../../../../context/qualifiers/hooks/useQualifierContext";
import { Product } from "../../../../context/qualifiers/types/StateType";

export const SubserviceHome = ({
	id,
	name,
	parentId,
	path,
	typeId,
	homeComponent,
	hideProductLevel,
}: SubserviceHomeProps) => {
	const HomeComponent: FunctionComponent = homeComponent!;

	const {
		state: { qualifiers, qualifiedUrl },
		dispatch,
		currentLevel,
		setCurrentLevel,
	} = useQualifierContext();

	const navigate = useNavigate();

	const pathname = path ? path : name;

	window.addEventListener("popstate", () => {
		if (window.location.pathname.includes(`/${pathname}`))
			navigate(`/${pathname}`);
	});

	const [_, setService] = useState<number>(0);

	useEffect(() => {
		setService(id);

		let subservice = window.location.pathname.split(`/${pathname}`)[1];
		let url: string = subservice === "/" ? "" : subservice;
		let urlArray: string[] = qualifiedUrl;

		if (url) urlArray = url.split("/").slice(1);

		if (qualifiers.length < 1 && qualifiedUrl !== urlArray) {
			dispatch({ type: "RESET_STATE" });
			setService((currentService) => {
				(async () => {
					const products: Product[] | null = await fetchProductsByUrl(
						url,
						currentService,
						dispatch
					);
					if (!products) navigate(`/${pathname}/${urlArray[0]}`);
				})();
				return currentService;
			});
		} else if (currentLevel < 1) {
			setCurrentLevel(currentLevel + 1);
			fetchNextLevel(
				parentId,
				dispatch,
				false,
				true,
				"qlfr.qualifier_type",
				typeId,
				hideProductLevel
			);
		}
	}, []);

	return (
		<Routes>
			<Route path="/*" element={<HomeComponent />} />
		</Routes>
	);
};
