import { useEffect } from "react";
import {
	useMediaQuery,
	useTheme,
	CircularProgress,
	Container,
} from "@mui/material";
import { WizardProps } from "./types/WizardProps";
import CancelIcon from "@mui/icons-material/Cancel";
import { sanitize } from "dompurify";
import { useInvokeWizard } from "../../context/wizard/hooks/useInvokeWizard";

export const Wizard = ({ src }: WizardProps) => {
	const { closeWizard } = useInvokeWizard();

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.between(0, 480));

	const source = sanitize(`${src}#${window.location.href}`);

	useEffect(() => {
		setTimeout(() => {
			if (isMobile) {
				closeWizard();
				window.open(source);
			}
		}, 200);
	}, [isMobile]);

	return (
		<div
			onClick={closeWizard}
			style={{
				position: "fixed",
				top: 0,
				height: "100vh",
				width: "100%",
				backgroundColor: "rgba(0,0,0,.7)",
				zIndex: 99990,
			}}
		>
			{isMobile ? (
				<Container
					fixed
					component={"div"}
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						width: "100%",
						height: "100%",
					}}
				>
					<CircularProgress
						variant="indeterminate"
						size={200}
						thickness={5}
					/>
				</Container>
			) : (
				<>
					<iframe
						title="support"
						src={source}
						style={{
							position: "relative",
							top: "50%",
							left: "50%",
							transform: "translate(-50%, -50%)",
							width: isMobile ? "100%" : "70%",
							height: isMobile ? "100%" : "70%",
							backgroundColor: "#fff",
							boxShadow: "0 10px 10px rgba(0,0,0,.3)",
							borderRadius: "5px",
							zIndex: 99991,
						}}
						frameBorder="0"
					></iframe>
					<CancelIcon
						color="error"
						style={{
							cursor: "pointer",
							position: "absolute",
							top: "17%",
							right: "16%",
							zIndex: 99999,
						}}
						onClick={closeWizard}
					/>
				</>
			)}
		</div>
	);
};
