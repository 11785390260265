import { Typography } from "@mui/material";
import { FooterProps } from "./types/FooterProps";

export const Footer = ({ backgroundColor, color }: FooterProps) => {
	return (
		<div style={{ backgroundColor, color }}>
			<Typography
				textAlign="center"
				fontSize="medium"
				sx={{ padding: "1rem" }}
			>
				<span style={{ fontSize: ".9rem" }}>&copy; </span>
				{new Date().getFullYear()} My Food &amp; Bev Training, a service
				of eStrategy Solutions, Inc.
			</Typography>
		</div>
	);
};
