import { NavItemsType } from "../components/LegacyNavbar/types/NavItems";

export const navItems: NavItemsType = [
	{
		name: "Home",
		linkTo: "/exxpressmart",
	},
	{
		name: "Log In",
		url: `${process.env.REACT_APP_PORTAL_URL}/mst/login`,
	},
	{
		name: "My Account",
		isDropdown: true,
		class: "ess-portal-account",
		dropdownItems: [
			{
				name: "Log In",
				url: `${process.env.REACT_APP_PORTAL_URL}/mst/login`,
			},
			{
				name: "My Course History",
				url: `${process.env.REACT_APP_TXN_URL}/activities/mst/history`,
				useWizard: true,
			},
			{
				name: "Forgot Password",
				url: `${process.env.REACT_APP_PORTAL_URL}/mst/password/reset`,
				useWizard: true,
			},
			{
				name: "Forgot Login ID",
				url: `${process.env.REACT_APP_PORTAL_URL}/mst/login/reset`,
				useWizard: true,
			},
		],
	},
	{
		name: "FAQ/Support",
		url: `${process.env.REACT_APP_TXN_URL}/helps/catalog/mst`,
		class: "ess-help",
		useWizard: true,
	},
];
