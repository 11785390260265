import { FC, useState, useEffect } from "react";
import { Box, Modal, Typography, IconButton } from "@mui/material";
import { decoratorApi, testimonialData } from "../../api/decorator.api";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CancelIcon from "@mui/icons-material/Cancel";
import type { Swiper as SwiperType } from "swiper";
import parse from "html-react-parser";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./styles/styles.css";
import { Testimonial } from "./Testimonial";

export const Testimonials: FC = () => {
	const [swiperInstance, setSwiperInstance] = useState<SwiperType>(
		{} as SwiperType
	);
	const [testimonialsData, setTestimonialsData] = useState(
		[] as testimonialData[]
	);
	const [modalState, setModalState] = useState({
		open: false,
		testimonialData: {} as testimonialData,
	});

	const theme = useTheme();
	const belowLg = useMediaQuery(theme.breakpoints.down("lg"));
	const belowMd = useMediaQuery(theme.breakpoints.down("md"));

	const handleModalOpen = (testimonialData: testimonialData) => {
		setModalState({ open: true, testimonialData });
		swiperInstance.autoplay.stop();
	};

	const handleModalClose = () => {
		setModalState({ open: false, testimonialData: {} as testimonialData });
		swiperInstance.autoplay.start();
	};

	useEffect(() => {
		(async () => {
			setTestimonialsData(await decoratorApi.getTestimonials());
		})();
	}, []);

	return (
		<>
			<Typography variant="h5" textAlign="center">
				What do our clients say?
			</Typography>
			<Swiper
				onInit={setSwiperInstance}
				slidesPerView={1}
				spaceBetween={30}
				pagination={{
					clickable: true,
				}}
				autoplay={{
					delay: 8000,
					disableOnInteraction: false,
				}}
				rewind={true}
				navigation={true}
				breakpoints={{
					640: {
						slidesPerView: 1,
					},
					768: {
						slidesPerView: 2,
					},
					1024: {
						slidesPerView: 3,
					},
					1500: {
						slidesPerView: 4,
					},
				}}
				modules={[Autoplay, Pagination, Navigation]}
				className="swiper-container-custom"
			>
				{testimonialsData.map((testimonialData, i) => (
					<SwiperSlide key={`swiperSlide.${i}`}>
						<Testimonial
							handleModalOpen={handleModalOpen}
							testimonialData={testimonialData}
						/>
					</SwiperSlide>
				))}
			</Swiper>
			<Modal open={modalState.open} onClose={handleModalClose}>
				<Box
					sx={{
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
						width: belowLg ? (belowMd ? "90%" : "60%") : "30%",
						maxHeight: "70%",
						bgcolor: "white",
						border: "2px solid transparent",
						borderRadius: 1,
						boxShadow: 24,
						display: "flex",
					}}
				>
					<IconButton
						sx={{
							position: "absolute",
							top: 3,
							right: 3,
						}}
						color="primary"
						size="small"
						onClick={handleModalClose}
					>
						<CancelIcon
							sx={{
								width: "24px",
								height: "24px",
							}}
						/>
					</IconButton>
					<Box
						sx={{
							padding: 3,
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							overflow: "auto",
						}}
					>
						<img
							style={{
								marginBottom: "16px",
								width: "auto",
								height: "60px",
							}}
							src={modalState.testimonialData.logo}
							alt="Client logo"
						/>
						<Box
							sx={{
								fontSize: "16px",
								textAlign: "center",
							}}
						>
							{modalState.testimonialData.body &&
								parse(modalState.testimonialData.body)}
						</Box>
						<Box
							sx={{
								marginTop: "16px",
								fontSize: "15px",
								textAlign: "center",
							}}
						>
							{modalState.testimonialData.name}
						</Box>
						<Box
							sx={{
								fontSize: "13px",
								textAlign: "center",
								color: "rgba(17, 17, 17, 0.5)",
							}}
						>
							{modalState.testimonialData.title}
						</Box>
					</Box>
				</Box>
			</Modal>
		</>
	);
};
