import { useCallback } from "react";
import useWizardContext from "./useWizardContext";

export const useInvokeWizard = () => {
	const {
		state: { wizardRequested },
		dispatchWizard,
	} = useWizardContext();

	const invokeWizard = useCallback(
		(url: string) => {
			dispatchWizard({
				type: "REQUEST_WIZARD",
				payload: { url },
			});
		},
		[wizardRequested]
	);

	const closeWizard = useCallback(() => {
		dispatchWizard({ type: "CLOSE_WIZARD" });
	}, [wizardRequested]);

	return { invokeWizard, closeWizard };
};
