import { FC, Suspense, useEffect, useState, lazy } from "react";
import { Container, Typography, Grid, CircularProgress } from "@mui/material";
import { Qualifiers } from "./Qualifiers";
import parse from "html-react-parser";
import useQualifierContext from "../../context/qualifiers/hooks/useQualifierContext";
import {
	Qualifier,
	QualifierValue,
} from "../../context/qualifiers/types/StateType";

const ProductCards = lazy(() => import("./ProductCards"));

// TODO: THIS TEXT WILL BE DRIVEN FROM admin IN THE FUTURE
const introText: string =
	`Our comprehensive Alcohol and Food Safety courses
    are certified and licensed in each state we service.&nbsp;` +
	`Our courses are offered exclusively online, with
    live and email help to ensure you complete your
    training quickly and easily.`;

export const Products: FC = () => {
	const {
		state,
		state: { isLoading, qualifiers },
	} = useQualifierContext();

	const [selectedProductLine, setSelectedProductLine] = useState<string>();

	useEffect(() => {
		const productLines = qualifiers.filter(
			(q: Qualifier) => q.level === 2
		)[0];
		if (productLines) {
			const selected = productLines.values.filter(
				(value: QualifierValue) => value.selected
			)[0];
			const value = selected?.value ?? undefined;
			if (value) setSelectedProductLine(value);
		}
	}, [state, qualifiers]);

	return (
		<Container>
			<Grid container columns={12} padding={1}>
				<Grid item lg={8} md={8} sm={12}>
					<Typography textAlign="center" fontSize="medium">
						{parse(introText)}
					</Typography>
				</Grid>
			</Grid>

			<Typography variant="h4" textAlign="center" marginBottom={3}>
				{selectedProductLine && selectedProductLine}
			</Typography>

			<Qualifiers isLoading={isLoading} />

			<Suspense fallback={<CircularProgress />}>
				<ProductCards />
			</Suspense>
		</Container>
	);
};
