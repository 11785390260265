import { FC, useEffect } from "react";
import { navItems } from "./navItems";
import { Link } from "react-router-dom";
import {
	AppBar,
	Box,
	Toolbar,
	Container,
	Divider,
	useTheme,
	useMediaQuery,
	Theme,
} from "@mui/material";
import logo from "../../img/logo_alternate.webp";
import useQualifierContext from "../../context/qualifiers/hooks/useQualifierContext";
import { MobileMenu } from "./MobileMenu";
import { DesktopMenu } from "./DesktopMenu";
import { Qualifier } from "../../context/qualifiers/types/StateType";
import { fetchNextLevel } from "../../api/helpers/fetchNextLevel";
import { useLocation } from "react-router-dom";
import { useFetchLevelAndProducts } from "../../context/qualifiers/hooks/useFetchLevelAndProducts";
import { serviceIds } from "../../service.map";

export const Navbar: FC = () => {
	const theme: Theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

	const { pathname } = useLocation();

	const {
		state: { qualifiers },
		dispatch,
	} = useQualifierContext();

	const { fetchNextLevelAndProducts } = useFetchLevelAndProducts();

	useEffect(() => {
		if (qualifiers.length <= 1) {
			fetchNextLevel(
				serviceIds.mfbt.parentId,
				dispatch,
				false,
				true,
				"qlfr.qualifier_type",
				2
			);
		}
	}, [pathname, qualifiers]);

	const productLines: Qualifier = qualifiers.filter(
		(q: Qualifier) => q.level === 2
	)[0];

	const getQualifiersByParentId = async (id: number, level: number) => {
		fetchNextLevelAndProducts(id, level, false);
	};

	return (
		<>
			<AppBar>
				<Container sx={{ maxWidth: "100vw", marginBottom: 0 }}>
					<Toolbar
						disableGutters
						sx={{
							display: "flex",
							flexFlow: "row wrap",
							justifyContent: "space-between",
							alignItems: "center",
						}}
					>
						<Box
							maxWidth="200px"
							sx={{ marginLeft: "2rem", marginRight: "2rem" }}
						>
							<Link to="/">
								<img
									loading="lazy"
									decoding="async"
									src={logo}
									alt="My Food &amp; Bev Training"
								/>
							</Link>
						</Box>

						{isMobile ? (
							<MobileMenu
								navItems={navItems}
								productLines={productLines}
								getQualifiersByParentId={
									getQualifiersByParentId
								}
							/>
						) : (
							<DesktopMenu
								navItems={navItems}
								productLines={productLines}
								getQualifiersByParentId={
									getQualifiersByParentId
								}
							/>
						)}
					</Toolbar>
				</Container>
			</AppBar>
			<Divider />
		</>
	);
};
