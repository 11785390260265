import createTheme from "@mui/material/styles/createTheme";
import { common } from "@mui/material/colors";

export const primaryColor: string = "#ad1515";
export const secondaryColor: string = "#171616";
export const lightGreyColor: string = "rgba(227,227,227,.5)";

const theme = {
	palette: {
		primary: {
			main: primaryColor,
		},
		secondary: {
			main: secondaryColor,
		},
		common: {
			white: common.white,
			black: common.black,
		},
	},
	typography: {
		fontFamily: "'Oxygen', sans-serif",
		fontSize: 20,
	},
	shape: {
		borderRadius: 5,
	},
	direction: "ltr",
	spacing: 8,
	components: {
		MuiTypography: {
			styleOverrides: {
				h1: {
					fontSize: "4rem",
					marginBottom: "3rem",
					padding: "3rem",
				},
				h2: {
					fontSize: "3rem",
					margin: "3rem",
				},
				h5: {
					margin: "1rem",
				},
				body1: {
					textTransform: "none",
				},
				body2: {
					underline: "hover",
					color: primaryColor,
					"&:hover": {
						textDecoration: "underline",
					},
				},
			},
		},
		MuiContainer: {
			defaultProps: {
				maxWidth: "xl",
				sx: {
					backgroundColor: common.white,
					color: common.black,
					marginBottom: "3rem",
				},
			},
		},
		MuiGrid: {
			defaultProps: {
				sx: {
					margin: "1rem auto",
				},
			},
		},
		MuiAppBar: {
			defaultProps: {
				position: "sticky",
				sx: { backgroundColor: common.white },
			},
		},
		MuiMenuItem: {
			defaultProps: {
				style: {
					padding: "1rem 2rem",
					fontSize: 16,
					textTransform: "uppercase",
					textDecoration: "none",
					color: common.black,
				},
			},
		},
		MuiAccordion: {
			defaultProps: {
				square: true,
				style: {
					border: "none",
					padding: 0,
					margin: 0,
					boxShadow: "none",
				},
			},
		},
		MuiListItem: {
			defaultProps: {
				style: {
					fontSize: 24,
				},
			},
		},
		MuiAccordionSummary: {
			defaultProps: {
				style: {
					padding: 0,
					margin: 0,
					height: 0,
				},
			},
		},
		MuiCard: {
			defaultProps: {
				sx: {
					minHeight: "100%",
					boxShadow: 1,
				},
			},
		},
		MuiTabs: {
			defaultProps: {
				sx: {
					"& .MuiTabs-indicator": {
						height: "5px",
					},
				},
			},
		},
		MuiLink: {
			defaultProps: {
				underline: "hover",
				target: "_blank",
				sx: { color: primaryColor },
			},
		},
		MuiIcon: {
			defaultProps: {
				fontSize: "large",
				sx: {
					color: common.white,
					padding: "25px",
					width: "100px",
					height: "100px",
					backgroundColor: primaryColor,
					display: "flex",
					flexFlow: "row wrap",
					justifyContent: "center",
					alignItems: "center",
					margin: "0 auto",
					borderRadius: "5px",
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				contained: {
					margin: "1rem",
				},
				outlined: {
					margin: "1rem",
				},
			},
		},
		MuiButtonBase: {
			styleOverrides: {
				root: {
					padding: 0,
				},
			},
		},
	},
} as const;

// https://dragoshmocrii.com/material-ui-custom-theme-and-typescript

type ExtendedTheme = {
	[Key in keyof typeof theme]: typeof theme[Key];
};

declare module "@mui/material/styles/createTheme" {
	interface Theme extends ExtendedTheme {}
	interface ThemeOptions extends ExtendedTheme {}
}

export default createTheme(theme);
