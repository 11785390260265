import { CssBaseline, Container } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchNextLevel } from "../api/helpers/fetchNextLevel";
import { fetchProductsByUrl } from "../api/helpers/fetchProductsByUrl";
import { Footer } from "../components/Footer";
import { Hubspot } from "../components/Hubspot";
import { Navbar } from "../components/Navbar";
import { Router } from "../routes/Router";
import theme from "./theme";
import { Product } from "../context/qualifiers/types/StateType";
import useQualifierContext from "../context/qualifiers/hooks/useQualifierContext";
import { findLegacySubservice } from "../api/helpers/findLegacySubservice";
import { serviceIds } from "../service.map";

export const ClientSite = () => {
	const {
		state: { qualifiedUrl, isLoadedHubspot },
		currentLevel,
		setCurrentLevel,
		dispatch,
	} = useQualifierContext();

	const [subserviceLoaded, setSubserviceLoaded] = useState<number>(0);
	const [_, setService] = useState<number>(0);

	const navigate = useNavigate();

	useEffect(() => {
		window.addEventListener("message", (event) => {
			if (typeof event.data === "string") {
				try {
					const message = JSON.parse(event.data);
					if (message.href) navigate(message.href);
				} catch (error) {
					return { error, data: event.data };
				}
			}
		});

		window.addEventListener("popstate", () => {
			if (window.location.pathname.includes("/products")) navigate("/");
		});

		let url: string =
			window.location.pathname.split("/products")[1] ?? undefined;
		let urlArray: string[] = qualifiedUrl;

		if (url) urlArray = url.split("/").slice(1);

		if (currentLevel < 1 && qualifiedUrl !== urlArray) {
			dispatch({ type: "RESET_STATE" });
			setService((currentService) => {
				(async () => {
					const products: Product[] | null = await fetchProductsByUrl(
						url,
						currentService,
						dispatch
					);
					if (!products) navigate(`/products/${urlArray[0]}`);
				})();
				return currentService;
			});
		} else if (currentLevel < 1) {
			setCurrentLevel(currentLevel + 1);
			fetchNextLevel(
				Number(serviceIds.mfbt.parentId),
				dispatch,
				false,
				true,
				"qlfr.qualifier_type",
				2
			);
		}
	}, []);

	return (
		<>
			<ThemeProvider theme={theme}>
				{/* CSS RESET */}
				<CssBaseline />
				<Container
					disableGutters
					id="back-to-top-anchor"
					sx={{ minWidth: "100vw" }}
				>
					{!subserviceLoaded && <Navbar />}
					<Container sx={{ padding: ".5rem 0" }}>
						<Router setSubserviceLoaded={setSubserviceLoaded} />
					</Container>
				</Container>

				{!findLegacySubservice() &&
					!subserviceLoaded &&
					!isLoadedHubspot && <Hubspot />}

				{!subserviceLoaded && <Footer />}
			</ThemeProvider>
		</>
	);
};
